<template>
  <div class="hero-section-container">
    <h1
      class="video-hero-title"
      v-if="
        data.hero_section_type === 'video' &&
        data.video_hero.title &&
        data.video_hero.title.length > 0
      "
      v-html="data.video_hero.title"
    />
    <MainButton
      v-if="data.video_hero.cta_button?.title"
      :text="data.video_hero.cta_button.title"
      :url="data.video_hero.cta_button.url"
      :target="data.video_hero.cta_button.target"
      :iconVisible="false"
      color="rainbow"
      width="full md:max-w-[33%] mx-auto -mt-8 mb-6"
      class="btn-hover"
    />
    <div v-if="data.hero_section_type === 'video'" class="content-video">
      <div class="arrow-l">
        <img class="slide-bottom" src="@/assets/img/arrow-down.svg" alt="" />
      </div>
      <div class="bottom-text-container">
        <div v-html="data.video_hero.video_hero_text" />
      </div>
      <div class="arrow-r">
        <img
          v-if="data.video_hero.video_hero_text"
          class="slide-bottom"
          src="@/assets/img/arrow-down.svg"
          alt=""
        />
      </div>
    </div>
    <div v-if="data.hero_section_type === 'video'" class="video-hero-container">
      <h3 class="video-title">{{ data.video_hero.video_title }}</h3>
      <div class="video-inner-container">
        <div class="play-button" @click="openModal()">
          <div
            class="bubble"
            :style="{ backgroundColor: data.video_hero.play_button_color }"
          ></div>
          <img src="@/assets/img/play.svg" />
        </div>
        <video
          :src="data.video_hero.video_preview"
          class="video"
          muted
          loop
          autoplay
          playsinline
        ></video>
      </div>
      <transition name="fade" mode="out-in" @after-leave="closeModal()">
        <div class="video-modal" v-if="videoActive">
          <div class="background" @click="videoActive = false"></div>
          <template v-if="!data.video_hero.is_vimeo">
            <video
              :src="data.video_hero.full_video"
              class="the-video"
              autoplay
              controls
              playsinline
            ></video>
          </template>
          <template v-if="data.video_hero.is_vimeo">
            <VideoPlayer
              class="the-video"
              :vimeo-id="data.video_hero.vimeo_id"
              :vimeo-hash="data.video_hero.vimeo_hash"
              :play-video="true"
            />
          </template>
        </div>
      </transition>
    </div>
    <div v-if="data.hero_section_type === 'text'" class="big-text-container">
      <div class="text-section-container">
        <div class="title-area">
          <h1 v-html="data.two_columns_big_title_hero.left_column.hero_title" />
        </div>
        <div class="text-area">
          <div
            class="internal-text"
            v-html="data.two_columns_big_title_hero.right_column.hero_text"
          />
          <MainButton
            v-if="data.two_columns_big_title_hero.right_column.cta_button.title"
            :text="
              data.two_columns_big_title_hero.right_column.cta_button.title
            "
            :url="data.two_columns_big_title_hero.right_column.cta_button.url"
            :target="
              data.two_columns_big_title_hero.right_column.cta_button.target
            "
            :iconVisible="true"
            color="orange"
            width="full"
          />
        </div>
      </div>
    </div>
    <div v-if="data.hero_section_type === 'text'" class="arrow-section">
      <div class="arrow-l">
        <img class="slide-bottom" src="@/assets/img/arrow-down.svg" alt="" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, getCurrentInstance } from 'vue'
import { ScrollSmoother } from '@/assets/js/ScrollSmoother.js'
import MainButton from '@/components/utility/Button.vue'
import VideoPlayer from '../utility/VideoPlayer.vue'

const videoActive = ref(false)
defineProps(['data'])
// defineProps(['data'])
// console.log(props.data)
const appInstance = getCurrentInstance()

const openModal = () => {
  videoActive.value = true
  const instance = ScrollSmoother.get()
  instance.kill()
  document.documentElement.classList.add('no-overflow')
}

const closeModal = () => {
  appInstance.root.ctx.scrollInstance()
  document.documentElement.classList.remove('no-overflow')
}

// console.log(props)
</script>
<style lang="scss" scoped>
.hero-section-container {
  @apply container justify-center flex flex-col pt-xl;

  .video-hero-title {
    @apply mb-md text-center;
  }
  .video-hero-container {
    h3 {
      @apply mb-sm mt-md heading text-white  relative w-screen md:w-full -left-[15px] rounded-none md:rounded-base md:-left-0;
    }
    .video-inner-container {
      @apply relative rounded-base w-full h-[380px] md:h-[430px] overflow-hidden mb-lg;
      .play-button {
        @apply absolute left-0 top-0 flex items-center justify-center h-full w-full cursor-pointer;
        .bubble {
          @apply absolute w-[90px] lg:w-[180px] h-[90px] lg:h-[180px] rounded-full z-1 transition-transform scale-100 duration-300 ease-in-out;
        }
        &:hover .bubble {
          @apply transition-transform scale-110 duration-300 ease-in-out;
        }
        img {
          @apply relative left-1 z-1 transform scale-50 lg:scale-100;
        }
      }
      .video {
        @apply relative left-[50%] top-[50%] w-full h-full transform translate-y-[-50%] translate-x-[-50%] object-cover object-top rounded-base;
      }
    }

    .video-modal {
      @apply fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-50;
      .background {
        @apply bg-curie-dark-gray/[0.9] h-full w-full top-0 left-0 absolute z-1 backdrop-blur-md cursor-pointer;
      }
      .the-video {
        @apply w-11/12 lg:w-8/12 h-auto z-2;
      }
    }
  }
  .big-text-container {
    @apply inner-container min-h-max lg:min-h-[432px] flex items-center;
    .text-section-container {
      @apply flex flex-col lg:flex-row w-full gap-x-sm;
      .title-area {
        @apply w-full lg:w-7/12 pb-md lg:pb-0;
      }
      .text-area:deep() {
        @apply w-full lg:w-5/12 pb-md lg:pb-0
        lg:flex lg:flex-col lg:justify-center;
        .internal-text {
          p:last-child {
            @apply mb-0;
          }
          h3 {
            @apply text-curie-blue pb-sm;
          }
          @apply pb-md;
        }
      }
    }
  }
  .content-video {
    @apply flex pb-xs justify-between lg:space-x-4;
    .bottom-text-container:deep() {
      @apply inner-container bg-transparent lg:bg-white rounded-base flex justify-center shadow-none lg:shadow-xl;
      div {
        @apply max-w-[795px] w-[795px] py-md px-4 xl:px-0;
        h3 {
          @apply text-curie-blue pb-sm;

          strong {
            @apply text-curie-blue;
          }
        }
      }
      ol,
      ul {
        @apply pl-9 md:pl-14 leading-6 font-FKGrotesk text-lg;
        margin-bottom: 22px;
      }
      ol {
        @apply list-none;
        counter-reset: item;

        li {
          @apply relative;
          counter-increment: item;

          &:before {
            @apply mr-2 w-5 text-right inline-block absolute -left-7;
            content: counter(item) '. ';
          }
        }
      }
      ul {
        li {
          @apply list-disc;
        }
      }
    }
  }

  .arrow-section {
    @apply pb-lg justify-between hidden lg:flex;
  }

  .arrow-r {
    @apply hidden lg:block;
  }

  .arrow-l {
    @apply hidden lg:block;
  }

  @keyframes slide-bottom {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(60px);
    }
  }

  .slide-bottom {
    animation: slide-bottom 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
      alternate both;
  }
}

.banner-module-section + .hero-section-container {
  padding-top: 0 !important;
}
</style>
