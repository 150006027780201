import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import VueLazyLoad from 'vue3-lazyload'
import { plugin, defaultConfig } from '@formkit/vue'
import VueScrollTo from 'vue-scrollto'
export const Application = createApp(App)

// Application.use(VueGtag, {
//   config: { id: 'UA-41682333-1' }
// })

Application.directive('scroll-to', VueScrollTo)

Application.use(router).use(VueLazyLoad, {
  adapter: {
    loaded ({
      bindType,
      el,
      naturalHeight,
      naturalWidth,
      $parent,
      src,
      loading,
      error,
      Init
    }) {
      setTimeout(() => el.classList.add('loaded'), 100)
    }
  }
}).use(plugin, defaultConfig).use(store).mount('#app')
router.beforeEach((to, from, next) => {
  next()
})
