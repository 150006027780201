<template>
  <section class="forms-section">
    <div class="form-group" :class="data.form_wide ? 'wide' : ''">
      <div class="form-group-top">
        <h3 class="form-section-title text-white mb-10">
          {{ data.forms_module_group.form_title }}
        </h3>
        <div class="form-section-text mb-15 text-text" v-html="data.form_text" />
      </div>
      <div class="form-area">
        <FormKit v-if="form" id="formKit" type="form" @submit="submitHandler">
          <FormKitSchema :schema="selectedForm" :library="library" />
        </FormKit>
        <div v-if="form" class="submit-button-container">
          <Button :text="submitButton" @click="$formkit.submit('formKit')" color="white" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { FormKitSchema } from "@formkit/vue";
import { reset } from "@formkit/core";
import Axios from "axios";
import Button from "@/components/utility/Button.vue";

export default {
  data() {
    return {
      disabled: false,
      message: "",
      library: {},
      form: null,
    };
  },
  components: {
    FormKitSchema,
    Button,
  },
  computed: {
    selectedForm() {
      const schema = [];
      this.form.fields.forEach((field) => {
        schema.push({
          $formkit: field.type === "phone" ? "tel" : field.type,
          name: field.label,
          // label: field.label,
          placeholder: field.placeholder,
          suffix: '',
          options: this.selectOptions(field.choices),
          classes: { outer: field.cssClass },
          validation: this.validationTypes(field.isRequired, field.type),
        });
      });
      schema.push({
        $el: "div",
        children: this.message.replace(/<\/?[^>]+(>|$)/g, ""),
        attrs: {
          class: "message-container mt-10 text-green-500 text-right",
        },
      });
      return schema;
    },
    submitButton() {
      return `${this.form.button.text}`;
    },
  },
  methods: {
    getForms() {
      return this.$store
        .dispatch("getSingleById", {
          type: "forms",
          id: this.data.forms_module_group.form_id,
        })
        .then(() => {
          this.theForm();
        });
    },
    theForm() {
      const form = this.$store.getters.singleById({
        type: "forms",
        id: this.data.forms_module_group.form_id,
      });
      this.form = form;
    },
    submitHandler(data) {
      this.disabled = true;
      let curedForm = {};
      for (const field of Object.entries(data)) {
        curedForm[
          `input_${this.form.fields.find((x) => {
            return x.label === field[0];
          }).id
          }`
        ] = field[1];
      }
      this.postData(this.form.submitURL, curedForm);
    },
    async postData(URL, body) {
      try {
        const response = await Axios.post(URL, body);
        this.message = response.data.confirmation_message ? response.data.confirmation_message : response.data.validation_messages[1];
        reset("formKit");
        this.disabled = false;
      } catch (error) {
        console.error(error);
      }
    },
    selectOptions(choices) {
      if (choices) {
        return choices.map((choice) => {
          return choice.text;
        });
      } else {
        return [];
      }
    },
    validationTypes(required, type) {
      const validation = [];
      if (required) {
        validation.push("required");
      }
      if (type === "email") {
        validation.push("email");
      }
      if (type === "phone") {
        validation.push("number");
        validation.push("length:10,10");
      }
      const finalValidation = validation.join("|");
      return finalValidation;
    },
    prevDef(event) {
      // console.log(1)
    },
  },
  props: ["data"],
  created() {
    this.getForms();
  },
};
</script>

<style lang="scss" scoped>
.forms-section {
  @apply bg-gray-800 text-white py-10 lg:pb-20 pt-24 px-4 xl:px-0;

  .form-group {
    @apply flex flex-col w-full max-w-screen-xl mx-auto lg:flex-row lg:gap-[182px];

    .form-group-top {
      @apply w-full lg:w-1/2;
    }

    .form-area {
      @apply w-full lg:w-1/2;
    }
  }

  .form-section-text:deep() {
    @apply max-w-2xl text-white flex flex-col pb-10 border-b border-white lg:pb-2;

    * {
      @apply mb-3;
    }

    strong {
      @apply font-normal text-[48px] lg:text-[32px];
    }

    a {
      @apply text-white underline;
    }
  }

  .wide {
    .form-group-top {
      @apply  w-[40%];
    }

    .form-area {
      @apply  w-full lg:w-[60%];
    }
  }

  .form-area:deep() {
    @apply w-full;

    .submit-button-container {
      @apply flex items-end justify-end;

      .button {
        @apply w-[200px];
      }
    }

    .formkit-form {
      @apply max-w-screen-xl flex flex-col lg:flex-row flex-wrap gap-[10px] items-end;

      input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }

      .formkit-messages {
        @apply text-right;

        li {
          @apply text-red-400 pt-1 pr-3 mr-3;
        }
      }

      & * {
        @apply w-full;
      }

      .formkit-outer {
        @apply mt-0;

        .formkit-messages {
          @apply text-left;

          li {
            @apply text-red-400 pt-1 pr-3 mr-3;
          }
        }
      }

      input,
      textarea,
      select {
        @apply w-full text-text placeholder-text border-none pb-4 mb-2 focus:outline-none focus:ring-0 focus:shadow-none bg-white;
      }

      textarea {
        @apply min-h-[260px];
      }

      .formkit-actions {
        @apply hidden;
      }

      .form-element {
        .formkit-messages {
          @apply text-left;
          li {
            @apply text-red-400 pt-1 pr-3 mr-3;
            &:not(:last-child) {
              @apply border-r border-red-300;
            }
          }
        }

        .formkit-wrapper {
          .formkit-inner {
            * {
              @apply mb-0;
            }
          }
        }

        &.half-width {
          @apply w-full lg:w-[48.9%];
        }

        &.full-width {
          @apply w-full;
        }
      }

      .gform_confirmation_message {
        @apply text-green-500;
      }

      div[data-type="submit"] {
        @apply flex justify-center mt-10;
      }

      div[data-type="submit"] {
        button:disabled {
          @apply opacity-50 cursor-not-allowed;
        }
      }
    }
  }
}
</style>