<template>
  <section class="services-scroller-container">
    <div class="services-scroller-inner-container">
      <h3 class="section-title">{{ data.title }}</h3>
      <div class="option-titles">
        <div
          class="service-title gradient-border"
          :class="`service-title-${index}`"
          v-for="(service, index) of data.services.scrolling_services"
          :key="`service-title-${index}`"
          @click="toSection(index)"
        >
          <p v-html="service.title" />
        </div>
      </div>
      <div class="options-content inner-container">
        <div
          class="service-content"
          :class="`service-content-${indx} ${
            activeIndex === indx ? 'active' : ''
          }`"
          v-for="(serviceContent, indx) of data.services.scrolling_services"
          :key="`service-content-${indx}`"
        >
          <div>
            <SerivceItemList
              :title="
                data.services.scrolling_services[indx].title
                  .replace(/\<br\/\>/g, ' ')
                  .replace(/\<br\>/g, ' ')
              "
              :text="serviceContent.content"
              :isActive="false"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="cta-section">
      <MainButton
        :text="data.call_to_action.title"
        :url="data.call_to_action.url"
        :target="data.call_to_action.target"
        :iconVisible="true"
        color="orange"
        width="full"
      />
    </div>
    <div
      class="toTop"
      @click="
        smoother.scrollTo(
          '.services-scroller-container',
          true,
          'top+=10% center'
        )
      "
    >
      <img src="@/assets/img/toTop.svg" class="arrow-toTop" />
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { ScrollSmoother } from '@/assets/js/ScrollSmoother.js'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import MainButton from '@/components/utility/Button.vue'
import SerivceItemList from './ServiceItemList.vue'

defineProps(['data'])
gsap.registerPlugin(ScrollTrigger)
const activeIndex = ref()
const smoother = ScrollSmoother.get()

const toSection = (index) => {
  activeIndex.value = index
  smoother.scrollTo(`.service-content-${index}`, true, 'center center')
}

onMounted(() => {
  ScrollTrigger.create({
    trigger: '.toTop',
    pin: '.toTop',
    start: 'center center',
    endTrigger: '.options-content',
    end: 'bottom 50%+=100px',
    markers: false
  })
  gsap.fromTo(
    '.toTop',
    {
      opacity: 0,
      pointerEvents: 'none'
    },
    {
      scrollTrigger: {
        trigger: '.options-content',
        start: 'top center',
        endTrigger: '.options-content',
        end: 'top+=30% center',
        scrub: true,
        markers: false
      },
      opacity: 1,
      pointerEvents: 'auto'
    }
  )
})

onBeforeUnmount(() => {
  const Alltrigger = ScrollTrigger.getAll()
  for (let i = 0; i < Alltrigger.length; i++) {
    Alltrigger[i].kill(true)
  }
})
</script>
<style lang="scss" scoped>
.services-scroller-container {
  @apply container relative;
  .services-scroller-inner-container {
    @apply flex flex-col items-center;
    .section-title {
      @apply heading text-white w-full mb-md;
    }
    .option-titles {
      @apply hidden lg:flex flex-wrap gap-sm justify-center mb-md;
      .service-title {
        @apply curie-gradient w-full lg:w-[23.5%] rounded-base p-sm flex items-center justify-center text-center relative cursor-pointer;
        box-shadow: 0px 4px 4px 0px #00000040;
        --borderWidth: 15px;
        p {
          @apply m-0 z-2 font-bold;
        }
        &:hover:before {
          border-radius: var(--borderWidth);
          transform: scaleX(0.97) scaleY(0.9);
        }
        &:before {
          @apply w-full h-full bg-white transition-all duration-300 ease-out;
          content: '';
          position: absolute;
          transform: scaleX(1) scaleY(1);
          border-radius: var(--borderWidth);
          z-index: 1;
          animation: animatedgradient 3s ease alternate infinite;
          background-size: 300% 300%;
        }
        &:after {
          @apply w-full h-full;
          content: '';
          position: absolute;
          transform: scaleX(1) scaleY(1);
          border-radius: var(--borderWidth);
          z-index: -1;
          animation: animatedgradient 3s ease alternate infinite;
          background-size: 300% 300%;
        }

        @keyframes animatedgradient {
          0% {
            background-position: 50% 0%;
          }
          50% {
            background-position: 50% 100%;
          }
          100% {
            background-position: 50% 0%;
          }
        }
      }
    }
    .options-content {
      @apply flex flex-col gap-y-sm w-full mb-sm;
      .service-content {
        @apply bg-white px-sm py-md rounded-base flex flex-col justify-center items-center relative;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        &:after {
          @apply content-[''] opacity-0 w-3 h-3 absolute left-4 top-4 bg-curie-blue rounded-full;
        }
        &.active:after {
          @apply content-[''] opacity-100 w-3 h-3 absolute left-4 top-4 bg-curie-blue rounded-full hidden lg:block;
        }
        &.active {
          box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
        }
        div:deep() {
          @apply lg:px-[22px];
          p {
            @apply m-0;
          }
          h3 {
            @apply mb-0;
          }
        }
      }
    }
  }
  .toTop {
    @apply absolute hidden lg:block right-0 top-0 cursor-pointer transition-transform duration-75 ease-in-out;
    .arrow-toTop {
      @apply transition-transform duration-150 ease-in-out transform scale-100;
      &:hover {
        @apply transform scale-95;
      }
    }
  }
  .cta-section {
    @apply mb-lg;
  }
}
</style>
