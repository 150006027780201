<template>
  <section class="video-section">
    <div v-if="data.title && data.title.length > 0" class="main-title mb-xs">
      <h3>
        <strong v-html="data.title" />
      </h3>
    </div>
    <div class="video-container">
      <div class="video-inner-container">
        <div class="play-button" @click="openModal()">
          <div class="bubble" :style="{ backgroundColor: '#4449FF' }"></div>
          <img src="@/assets/img/play.svg" />
        </div>
        <video
          :src="data.video_preview"
          class="video"
          muted
          loop
          autoplay
          playsinline
        ></video>
      </div>
      <transition name="fade" mode="out-in" @after-leave="closeModal()">
        <div class="video-modal" v-if="videoActive">
          <div class="background" @click="videoActive = false"></div>
          <template v-if="data.id">
            <VideoPlayer
              class="the-video"
              :vimeo-id="data.id"
              :vimeo-hash="data.vimeo_hash"
              :play-video="true"
            />
          </template>
        </div>
      </transition>
    </div>
  </section>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import { ScrollSmoother } from '@/assets/js/ScrollSmoother.js'
import VideoPlayer from '../utility/VideoPlayer.vue'
const videoActive = ref(false)

const appInstance = getCurrentInstance()

const openModal = () => {
  videoActive.value = true
  const instance = ScrollSmoother.get()
  instance.kill()
  document.documentElement.classList.add('no-overflow')
}

const closeModal = () => {
  appInstance.root.ctx.scrollInstance()
  document.documentElement.classList.remove('no-overflow')
}
defineProps(['data'])
</script>

<style lang="scss" scoped>
.video-section {
  @apply container;
  .main-title:deep() {
    * {
      @apply text-white relative w-screen -left-[15px] md:w-full md:-left-0;
    }
    *:not(strong) {
      @apply heading  relative w-screen md:w-full -left-[15px] rounded-none md:rounded-base md:-left-0;
    }
    h3 {
      @apply font-normal;
    }
    strong {
      @apply font-bold inline-block ml-4 lg:inline md:ml-0 md:mr-3 w-full;
    }
  }
  .video-container {
    @apply flex flex-col items-center justify-center py-3;
    .video-inner-container {
      @apply relative rounded-base overflow-hidden
      h-[180px] w-[320px]
      md:w-[640px] md:h-[360px];
      .play-button {
        @apply absolute left-0 top-0 flex items-center justify-center h-full w-full cursor-pointer;
        .bubble {
          @apply absolute w-[90px] lg:w-[180px] h-[90px] lg:h-[180px] rounded-full z-1 transition-transform scale-100 duration-300 ease-in-out;
        }
        &:hover .bubble {
          @apply transition-transform scale-110 duration-300 ease-in-out;
        }
        img {
          @apply relative left-1 z-1 transform scale-50 lg:scale-100;
        }
      }
      .video {
        @apply relative left-[50%] top-[50%] w-full h-full transform translate-y-[-50%] translate-x-[-50%] object-cover object-top rounded-base;
      }
    }

    .video-modal {
      @apply fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-50;
      .background {
        @apply bg-curie-dark-gray/[0.9] h-full w-full top-0 left-0 absolute z-1 backdrop-blur-md cursor-pointer;
      }
      .the-video {
        @apply w-11/12 lg:w-8/12 h-auto z-2;
      }
    }
  }
}
</style>
