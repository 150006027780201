<template>
  <section class="TOUPP">
    <div class="title-section">
      <h1>{{ title }}</h1>
      <p>Update {{ lastModified }}</p>
    </div>
    <div class="custom-section" v-html="data.module_html_raw_editor" />
  </section>
</template>

<script setup>
import { useStore } from 'vuex'
import { onBeforeMount, ref } from 'vue'
const store = useStore()
const props = defineProps(['data', 'slug'])

const lastModified = ref()
const title = ref()

onBeforeMount(async () => {
  await store.dispatch('getSingleBySlug', {
    type: 'pages',
    slug: props.slug,
    showLoading: true
  })
  const pageData = store.getters.singleBySlug({
    type: 'pages',
    slug: props.slug,
    showLoading: true
  })
  lastModified.value = pageData.last_modified
  title.value = pageData.title.rendered
})
</script>

<style lang="scss" scoped>
.TOUPP {
  @apply inner-container max-w-[770px];
  .title-section {
    @apply pb-lg;
    h1 {
      @apply text-[60px];
    }
    p {
      @apply text-curie-blue font-bold large;
    }
  }
  .custom-section:deep() {
    p {
      @apply large pb-md;
    }
    h3 {
      @apply font-bold pt-md pb-md;
    }
    ol,
    ul {
      li {
        @apply large pb-md pl-8 relative;
        &:before {
          @apply content-[''] h-3 w-3 bg-curie-blue absolute rounded-full left-0 top-2;
        }
      }
    }
  }
}
</style>
