<template>
  <div class="wrapper">
    <main>
      <div
        class="section-404 container w-full h-screen max-w-screen-xl flex flex-col justify-center"
      >
        <section class="text-center mb-6">
          <p>
            Take me to the
            <a href="#" @click="$router.go(-1)">previous</a>
            page
          </p>
        </section>
        <section
          class="error-404 module flex flex-col items-center justify-center"
        >
          <h1 class="h2 mb-6">Your page can’t be found :(</h1>
          <div class="error404-message element-line line-blue">
            <p>
              Take me back
              <a href="/">home</a>
            </p>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'mainFooter',
  data () {
    return {}
  },
  created () {
    this.$store.dispatch('updateDocTitle', {
      parts: ['Page not found', this.$store.state.site.name]
    })
  }
}
</script>

<style lang="scss" scoped>
.section-404 {
  a {
    @apply underline text-black;
  }
}
</style>
