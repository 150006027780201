<template>
  <section v-if="show" class="banner-module-section">
    <div class="inner-container">
      {{ props.data.content }}
      <span v-if="!props.data.CTA_button.text">
        <a :href="props.data.CTA_button.url" :target="props.data.CTA_button.target">
          {{ props.data.CTA_button.title }}
          <img src="@/assets/img/banner_arrow.svg" />
        </a>
      </span>
    </div>
    <img src="@/assets/img/close.svg" alt="" class="banner-close" @click="closeBanner()">
    </section>
</template>
<script setup>
import { ref, onBeforeMount } from 'vue'
const props = defineProps(['data'])
const show = ref(true)

onBeforeMount(() => {
  if (localStorage.getItem('showBanner') === null) {
    localStorage.setItem('showBanner', true)
  }

  const showBanner = localStorage.getItem('showBanner')

  if (showBanner === 'false') {
    show.value = false
  } else {
    show.value = true
  }
})

const closeBanner = () => {
  show.value = false
  localStorage.setItem('showBanner', false)
}

</script>
<style lang="scss" scoped>
.banner-module-section {
  @apply w-full bg-curie-yellow py-4 px-4 mb-12 flex gap-x-4 justify-between items-center relative;
  .inner-container {
    @apply w-full max-w-[84%] large text-black items-start lg:items-center;
    span {
      @apply ml-0 lg:ml-3 text-black underline inline-block ml-2;
      a {
        @apply flex items-center;
        img {
          @apply ml-2 invert;
        }
      }
    }
  }
  .banner-close {
    @apply relative scale-[2] mr-6 cursor-pointer invert;
  }
}
</style>
