<template>
  <footer class="site-footer" v-if="!store.state.site.loading">
    <div class="gutter-wrapper">
      <div class="footer-container">
        <div class="footer-head">
          <a href="/">
            <img
              v-if="acfOptions.site_logo_footer"
              class="h-auto w-60"
              :src="acfOptions.site_logo_footer.url"
              :alt="acfOptions.site_logo_footer.alt"
            />
          </a>
          <footer-menu name="footer-menu" />
        </div>
        <div class="footer-body">
          <p class="copyright">{{ acfOptions.copyright }} {{ currentYear }}</p>
          <div class="social-media">
            <div class="social-wrap">
              <a
                v-if="acfOptions.facebook"
                :href="acfOptions.facebook"
                target="_blank"
              >
                Facebook
              </a>
              <a
                v-if="acfOptions.linkedin"
                :href="acfOptions.linkedin"
                target="_blank"
              >
                <img src="@/assets/img/linkedin.svg" alt="LinedIn Logo" />
              </a>
              <a
                v-if="acfOptions.youtube"
                :href="acfOptions.youtube"
                target="_blank"
              >
                Youtube
              </a>
              <a
                v-if="acfOptions.twitter"
                :href="acfOptions.twitter"
                target="_blank"
              >
                Twitter
              </a>
              <a
                v-if="acfOptions.instagram"
                :href="acfOptions.instagram"
                target="_blank"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import FooterMenu from '@/components/template-parts/FooterMenu.vue'
import { useStore } from 'vuex'
const currentYear = new Date().getFullYear()

const store = useStore()
const acfOptions = store.getters.acfOptions()
</script>

<style lang="scss" scoped>
.site-footer {
  @apply w-full mx-auto bg-white text-curie-dark-gray;
}

.gutter-wrapper {
  @apply w-full max-w-screen-xl mx-auto;
}

.footer-container {
  @apply divide-text px-10 2xl:px-0 py-8 flex flex-col gap-20;
}

.footer-head {
  @apply flex flex-col md:items-center md:flex-row md:justify-between;
  nav {
    @apply w-full md:w-1/2 lg:w-1/3;
  }
}

.footer-body {
  @apply flex flex-row justify-between items-center;

  .copyright {
    @apply text-curie-med-gray text-[18px] lg:pt-4;
  }
  .social-media {
    @apply md:w-1/2 lg:w-1/3  flex flex-row justify-end;
    .social-wrap {
      @apply w-1/2 flex gap-3 justify-end md:justify-start;
    }
  }
}
</style>
