<template>
  <div class="button-wrapper w-auto" :class="`${color} ${width}`">
    <a v-if="url" class="button cursor-pointer" :href="url" :target="target">
      {{ text }}
      <span class="button-icon" v-if="iconVisible">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0V2H12.59L0.29 14.29L1.71 15.71L14 3.41V16H16V0H0Z"
            fill="#202020"
          />
        </svg>
      </span>
    </a>
    <button v-else-if="!url && func" @click="func(args)">
      {{ text }}
      <span class="button-icon" v-if="iconVisible">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0V2H12.59L0.29 14.29L1.71 15.71L14 3.41V16H16V0H0Z"
            fill="#202020"
          />
        </svg>
      </span>
    </button>
    <div v-else class="button cursor-pointer">
      {{ text }}
      <span class="button-icon" v-if="iconVisible">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0V2H12.59L0.29 14.29L1.71 15.71L14 3.41V16H16V0H0Z"
            fill="#202020"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { gsap } from 'gsap'
import { ScrollSmoother } from '@/assets/js/ScrollSmoother.js'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
const smoother = ScrollSmoother.get()
const router = useRouter()

const props = defineProps({
  text: String,
  url: String,
  target: String,
  color: String,
  width: String,
  func: Function,
  args: Number,
  iconVisible: Boolean
})

const {
  text,
  url,
  target,
  color,
  width,
  func,
  args,
  iconVisible = false
} = toRefs(props)

onBeforeUnmount(() => {
  const Alltrigger = ScrollTrigger.getAll()
  for (let i = 0; i < Alltrigger.length; i++) {
    Alltrigger[i].kill(true)
  }
})
</script>

<style lang="scss" scoped>
.button-wrapper {
  @apply relative z-1 rounded-base flex justify-center;

  .button {
    @apply flex items-center gap-5 grow-0 w-max py-[25px] px-14
            font-FKGrotesk font-bold text-[22px] leading-7
            transition-colors ease-linear duration-500;

    .button-icon {
      @apply transition-all ease-in-out duration-200;
      svg {
        path {
          @apply fill-curie-dark-gray transition-all ease-in-out duration-1000;
        }
      }
    }
  }
  &.orange {
    @apply text-curie-dark-gray border-curie-orange bg-curie-orange;

    &::before {
      @apply absolute content-[""] top-0 right-0 bottom-0 left-0 -z-1 transition-opacity duration-500 ease-linear opacity-0 curie-gradient rounded-base;
    }

    &:hover {
      @apply text-white;
      &::before {
        @apply opacity-100;
      }
      .button-icon {
        svg {
          path {
            @apply fill-white;
          }
        }
      }
    }
  }

  &.rainbow {
    @apply text-white curie-gradient scale-100 transition-transform;

    &::before {
      @apply absolute content-[""] top-0 right-0 bottom-0 left-0 -z-1 transition-opacity duration-500 ease-linear opacity-0 curie-gradient rounded-base;
    }

    &:hover {
      @apply text-white scale-[102%];
      &::before {
        @apply opacity-100;
      }
      .button-icon {
        svg {
          path {
            @apply fill-white;
          }
        }
      }
    }
  }

  &.blue {
    @apply text-white border-curie-blue bg-curie-blue;

    .button-icon {
      svg {
        path {
          @apply fill-white;
        }
      }
    }

    &::before {
      @apply absolute content-[""] top-0 right-0 bottom-0 left-0 -z-1 transition-opacity duration-500 ease-linear opacity-0 curie-gradient rounded-base;
    }

    &:hover {
      &::before {
        @apply opacity-100;
      }
    }
  }
  &.transparent-white {
    @apply text-white bg-transparent border-transparent transition-all duration-500 ease-linear;
    .button-icon {
      svg {
        path {
          @apply fill-white;
        }
      }
    }
    :hover {
      .button-icon {
        svg {
          @apply scale-125;
        }
      }
    }
  }
  &.transparent-dark-gray {
    @apply text-curie-dark-gray bg-transparent border-transparent transition-all duration-500 ease-linear;
    .button-icon {
      svg {
        path {
          @apply fill-curie-dark-gray;
        }
      }
    }
    :hover {
      .button-icon {
        svg {
          @apply scale-125;
        }
      }
    }
  }
  &.full {
    @apply w-full;

    .button {
      width: 100%;
      justify-content: center;
    }
  }
  &.content {
    @apply w-max;
  }
}
</style>
