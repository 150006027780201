<template>
  <header
    ref="header"
    class="no-cursor header-nav white"
    :class="`${activeState ? 'nav-open' : ''}`"
    id="header-nav"
  >
    <nav
      ref="navWrapper"
      class="navWrapper"
      :class="
        scrolling
          ? `bg-transparent lg:bg-white rounded-b-base
            shadow-xl relative -top-10 rounded-t-none lg:-top-4 transition-transform
            duration-200 ease-in-out`
          : 'bg-transparent relative -top-0'
      "
    >
      <div
        class="branding"
        :class="scrolling ? `rounded-b-base rounded-t-none` : 'rounded-base'"
      >
        <div class="logo-container">
          <a href="/" title="Logo" @click="close">
            <Transition name="fade" mode="out-in">
              <img
                v-if="site.site_logo?.url"
                :src="site.site_logo?.url || ''"
              />
            </Transition>
          </a>
        </div>
        <div
          class="mobile-nav"
          @click="toggle"
          :class="{ active: activeState }"
        >
          <span class="line line-one" />
          <span class="line line-two" />
          <span class="line line-three" />
        </div>
      </div>
      <div class="desktop">
        <ul class="nav-container" ref="nav" key="nav-container">
          <template v-if="store.state.site.introShown">
            <transition-group v-for="(item, index) in menu" :key="item.id">
              <li
                class="menu-items"
                :data-ind="index"
                :key="item.id"
                @click="close"
              >
                <router-link
                  :to="$root.convertToRelative(item.url)"
                  exact
                  :class="scrolling ? 'py-0 lg:py-[20px]' : 'py-0 lg:py-[26px]'"
                  >{{ item.content }}</router-link
                >
              </li>
            </transition-group>
          </template>
        </ul>
      </div>
      <transition-group name="slide-fade" mode="in-out">
        <template v-if="store.state.site.introShown">
          <!-- <Button
            v-if="lastItem.length > 0"
            :url="lastItem[0].url"
            :target="lastItem[0].target"
            :text="lastItem[0].content"
            :color="`orange`"
            class="ml-2"
          /> -->
          <div class="mobile" v-if="activeState">
            <ul class="nav-container" ref="nav" key="nav-container">
              <li
                v-for="(item, index) in menu"
                class="menu-items"
                :data-ind="index"
                :key="item.id"
                @click="close"
              >
                <router-link :to="$root.convertToRelative(item.url)" exact>{{
                  item.content
                }}</router-link>
              </li>
            </ul>
          </div>
        </template>
      </transition-group>
    </nav>
  </header>
</template>

<script setup>
import { ref, onUnmounted, onBeforeMount, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
// import Button from '../../utility/Button.vue'

const store = useStore()
const menu = store.getters.menu({ name: 'main-menu' })
// const mobileMenu = [...menu]
// const lastItem = mobileMenu.splice(-1)
const site = store.getters.acfOptions()
const activeState = ref(false)
const curr = getCurrentInstance()
const scrollTop = ref(0)
const scrolling = ref(false)

function toggle () {
  activeState.value = !activeState.value
  const html = document.querySelector('html')

  if (activeState.value === true) {
    html.classList.add('no-overflow')
    curr.root.data.locoScroll.paused(true)
  } else {
    html.classList.remove('no-overflow')
    curr.root.data.locoScroll.paused(false)
  }
}

function close () {
  if (activeState.value === true) {
    toggle()
  }
}

function closeHandler (e) {
  if (e.keyCode === 27 && activeState.value === true) {
    toggle()
  }
}

function handleScroll () {
  scrollTop.value = window.pageYOffset
  if (scrollTop.value >= 100) {
    scrolling.value = true
  } else {
    scrolling.value = false
  }
}

onBeforeMount(() => {
  document.addEventListener('keyup', closeHandler, { passive: true })
  document.addEventListener('scroll', handleScroll, { passive: true })
})

onUnmounted(() => {
  document.removeEventListener('keyup', closeHandler, false)
  document.removeEventListener('scroll', handleScroll, false)
})
</script>

<style lang="scss" scoped>
.header-nav {
  @apply fixed flex items-stretch my-0 py-10 px-[15px] lg:py-4 lg:px-10
         justify-center w-full top-0
         max-h-screen lg:h-auto
         bg-transparent overflow-hidden lg:overflow-visible z-10
         transition-all duration-500 ease-in-out;
  nav {
    @apply flex flex-col lg:flex-row items-stretch
    lg:items-center w-full max-w-screen-2xl pt-0 xl:px-3 2xl:px-0 lg:py-0
     transition-all ease-in-out;
    .branding {
      @apply w-full lg:w-[60%] xl:w-[65%] text-center
            md:text-left flex items-center lg:max-h-[65px]
            justify-between py-[15px] px-4 xl:p-0 bg-white lg:bg-transparent;
      .logo-container {
        @apply h-full p-0 lg:p-[25px] xl:max-h-full flex items-center lg:inline-block transition-all duration-300 ease-in-out;
        img {
          @apply lg:w-auto h-full max-h-[30px] lg:max-h-[80px];
        }
      }
      .mobile-nav {
        @apply flex flex-col lg:hidden float-right cursor-pointer gap-[9px];
        .line {
          @apply transition-all ease-in-out;
        }
        .line:before {
          @apply content-[''] block h-[2px] w-8 bg-curie-dark-gray;
        }
        &.active {
          .line:before {
            @apply bg-white;
          }
        }
        &:hover {
          .line:before {
            @apply curie-gradient;
          }
        }
      }
    }
    .desktop {
      @apply w-full hidden lg:flex;
    }
    .mobile {
      @apply lg:hidden;
    }
    ul {
      @apply flex flex-col my-3 lg:my-0 lg:flex-row justify-start lg:gap-4 items-center lg:items-end md:px-0 lg:px-5 xl:px-0;
      li:nth-child(1) {
        @apply inline-block ml-0 lg:w-auto;
      }
      li {
        @apply w-full lg:w-auto text-left lg:text-center;
      }
      .menu-items {
        @apply flex flex-col py-[25px] lg:py-0 fill-white stroke-white relative items-center border-b lg:border-b-0;
        border-color: rgba(245, 245, 245, 0.2);
        a {
          @apply text-white lg:text-curie-dark-gray self-center lg:self-start duration-500 ease-in-out transition-all
          font-FKGrotesk font-normal text-[28px] leading-[30px] lg:text-[22px] lg:leading-7
          w-full h-full relative overflow-hidden;

          &::after {
            @apply content-[""] absolute bottom-0 left-0 w-full h-full duration-200 ease-in-out transition-all
            border-curie-orange;
          }

          &.router-link-active,
          &:hover {
            @apply text-curie-orange lg:text-inherit;
            &::after {
              @apply lg:border-b-[5px];
            }
          }
        }
      }
    }
  }

  &:deep(.nav-container) {
    @apply lg:flex place-items-start justify-start lg:justify-around lg:items-center
           lg:px-[20px]
           w-full bg-transparent lg:bg-white rounded-base
           text-center
           transition ease-in-out m-0
           h-screen lg:h-fit  order-2 lg:order-1;
  }
  .button-wrapper:deep() {
    @apply my-3 lg:my-0 order-1 lg:order-2;
    .button {
      @apply w-full lg:w-max justify-center lg:justify-start;
    }
  }
  &.nav-open {
    @apply bg-curie-dark-gray lg:bg-white;
    .line-one {
      @apply transform rotate-45 translate-y-4 translate-x-[0px];
    }
    .line-three {
      @apply transform -rotate-45 -translate-y-[6px] -translate-x-[0px];
    }
    .line-two {
      @apply opacity-0;
    }
    .branding {
      @apply bg-transparent lg:bg-white;
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.5s;
}
.slide-fade-leave-active {
  transition: all 0.3s;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-60px);
  opacity: 0;
}
</style>
