<template>
  <section class="contact-section">
    <div class="inner-wrapper">
      <div class="text-wrapper">
        <h1 class="contact-title" v-if="form.title && form.title.length > 0">{{form.title}}</h1>
        <p v-if="form.description && form.description.length > 0">{{form.description}}</p>
      </div>
      <div class="form-wrapper" v-if="props.data.form && isFormValid">
        <template v-if="isDataFormLoaded">
          <div v-show="messages.length > 0" class="messages-wrapper">
            <p class="title">Please check the following fields:</p>
            <ul id="errorList" class="validation-errors">
              <li v-for="(message, indexMessage) in messages" :key="indexMessage">{{ message }}</li>
            </ul>
          </div>
          <FormKit type="form"
          id="curieForm"
            :submit-label="submitLabelButton"
            @submit="submitHandler"
            v-model="requestFormData"
          >
            <FormKitSchema
              :schema="schema"
              :data="data">
            </FormKitSchema>
          </FormKit>
        </template>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref, onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { FormKitSchema } from '@formkit/vue'
import { reset } from '@formkit/core'
import Axios from 'axios'
import { ScrollSmoother } from '@/assets/js/ScrollSmoother.js'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
  data: Object
})
const store = useStore()
const router = useRouter()
const isDataFormLoaded = ref(false)
const isFormValid = ref(false)
const disabled = ref(false)
const fullRegexPhoneExpression = ref(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
const form = ref({})
const requestFormData = ref({})
const schema = ref({})
const storeParams = ref({})
const messages = ref([])
const submitLabelButton = ref('Submit')
const smoother = ScrollSmoother.get()
const labelForOther = 'Other'
const data = reactive({
  onSelectOther: (fieldId, targetValue) => {
    if (!fieldId || fieldId === undefined || fieldId === null) {
      return false
    }
    if (requestFormData.value && fieldId in requestFormData.value && requestFormData.value[fieldId]) {
      return requestFormData.value[fieldId].includes(targetValue)
    }

    return false
  },
  onSelectFile: (fieldId) => {
    if (!fieldId || fieldId === undefined || fieldId === null) {
      return false
    }
    if (requestFormData.value && fieldId in requestFormData.value && requestFormData.value[fieldId]) {
      return requestFormData.value[fieldId].length > 0
    }

    return false
  }
})

const redirectToSuccess = () => {
  reset('curieForm')
  const path = props.data.final_message.url.replace(store._state.data.site.url, '')
  router.push(path)
}

const showErrors = (data) => {
  messages.value = []
  const keys = Object.keys(data)

  keys.forEach(element => {
    const fieldSettings = form.value.fields.find((x) => {
      return x.id === parseInt(element)
    })
    const name = fieldSettings.placeholder === '' ? fieldSettings.label : fieldSettings.placeholder
    messages.value.push(`${name}: ${data[element]}`)
  })

  smoother.scrollTo('#errorList', true, 'center center')
}

const submitHandler = (data, node) => {
  disabled.value = true
  submitLabelButton.value = 'Sending...'
  const dataCopy = { ...data }
  const dataToSend = Object.keys(dataCopy).filter((key) => !key.includes('other'))
  const curedForm = new FormData()
  let value = ''
  for (const field of dataToSend) {
    const fieldSettings = form.value.fields.find((x) => {
      const arrayId = field.split('_')
      // eslint-disable-next-line eqeqeq
      return x.id == arrayId[1]
    })
    const key = `input_${fieldSettings.id}`
    if (fieldSettings.type !== 'checkbox') {
      value = (fieldSettings.type === 'fileupload' ? data[field].map(a => a.file)[0] : data[field] ?? '')
      if (fieldSettings.enableOtherChoice && value === labelForOther) {
        value = 'gf_other_choice'
        curedForm.append(`${key}_other`, data[`field_${fieldSettings.id}_other`])
      }

      if (value && value !== '') {
        curedForm.append(key, value)
      }
    } else {
      data[field].forEach((element, index) => {
        curedForm.append(`${key}.${(index + 1)}`, element)
      })
    }
  }

  postData(form.value.submitURL, curedForm, node)
}

const postData = async (URL, body, node) => {
  try {
    const response = await Axios.post(URL, body, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    disabled.value = false
    submitLabelButton.value = form.value.button.text
    if (response.status === 200 && response.data.is_valid) {
      node.clearErrors()
      redirectToSuccess()
    } else {
      disabled.value = false
      submitLabelButton.value = form.value.button.text
      showErrors(response.data.validation_messages)
    }
  } catch (error) {
    disabled.value = false
    submitLabelButton.value = form.value.button.text
    node.setErrors(error.formErrors, error.fieldErrors)
    console.error(`There was an error submitting the form: ${error}`)
  }
}

const selectOptions = (choices) => {
  if (choices) {
    return choices.map((choice) => {
      return choice.text
    })
  } else {
    return []
  }
}

const validationTypes = (required, type) => {
  const validationPhone = `matches:${fullRegexPhoneExpression.value}`
  const validation = []
  if (required) {
    validation.push('required')
    if (type === 'phone') {
      validation.push(validationPhone)
    }
  }
  if (type === 'email') {
    validation.push('email')
  }
  if (type === 'phone') {
    validation.push(validationPhone)
  }
  const finalValidation = validation.join('|')
  return finalValidation
}

const returnFormKiType = (type) => {
  switch (type) {
    case 'phone':
      return 'tel'
    case 'fileupload':
      return 'file'
    default:
      return type
  }
}

const setFormSchema = () => {
  submitLabelButton.value = form.value.button.text
  const schema = []
  let fieldSettings = {}
  let extraFieldSettings = {}
  let thereIsExtraField = false
  form.value.fields.forEach(field => {
    if (field.type === 'section') {
      fieldSettings = {
        $el: 'div',
        attrs: {
          class: 'formkit-outer'
        },
        children: [{
          $el: 'p',
          attrs: {
            class: 'input-section'
          },
          children: field.label
        }]
      }
    } else {
      fieldSettings = {
        $formkit: returnFormKiType(field.type),
        id: `field_${field.id}`,
        name: `field_${field.id}`,
        label: field.labelPlacement ? '' : field.label,
        placeholder: field.placeholder,
        validation: validationTypes(field.isRequired, field.type),
        validationLabel: field.label === '' ? field.placeholder : field.label,
        help: field.description,
        outerClass: field.labelPlacement ? '' : 'full-label'
      }
      if (field.type === 'select' || field.type === 'radio' || field.type === 'checkbox') {
        fieldSettings.options = selectOptions(field.choices)

        if (field.enableOtherChoice) {
          thereIsExtraField = true
          const currentId = `field_${field.id}`
          const currentIdOther = `${currentId}_other`
          fieldSettings.options.push(labelForOther)

          extraFieldSettings = {
            $formkit: 'text',
            if: `$get(${currentId}).value === '${labelForOther}'`,
            id: currentIdOther,
            name: currentIdOther,
            placeholder: `Enter value for Other ${fieldSettings.label}`,
            validation: validationTypes(true, 'text'),
            validationLabel: `Other ${fieldSettings.label}`
          }
        }
      }
      if (field.type === 'textarea') {
        fieldSettings.rows = 8
      }
      if (field.type === 'fileupload') {
        fieldSettings.accept = field.allowedExtensions ?? ''
        fieldSettings.multiple = field.multipleFiles ?? false
        if (field.adminLabel) {
          const config = field.adminLabel.split(' ')

          if (config[1] > 0) {
            const target = form.value.fields.find((x) => {
              // eslint-disable-next-line eqeqeq
              return x.adminLabel == `${config[0]} ${parseInt(config[1]) -1 }`
            })
            if (target) {
              fieldSettings.if = `$onSelectFile('field_${target.id}') === true`
            }
          }
        }
      }
      if (field.conditionalLogic && field.conditionalLogic.enabled) {
        if (field.conditionalLogic.actionType === 'show') {
          let allRules = ''
          field.conditionalLogic.rules.forEach((rule, indexRule) => {
            const operator = rule.operator === 'is' ? '===' : '!=='
            const fieldtarget = form.value.fields.find((x) => {
              // eslint-disable-next-line eqeqeq
              return x.id == rule.fieldId
            })
            if (fieldtarget) {
              allRules += fieldtarget.type === 'checkbox' ? `$onSelectOther('field_${rule.fieldId}', '${rule.value}') === true` : `$get(field_${rule.fieldId}).value ${operator} '${rule.value}'`
            }
            if (field.conditionalLogic.rules.length > (indexRule + 1)) {
              allRules += '&&'
            }
          })
          fieldSettings.if = allRules
        }
      }
    }

    schema.push(fieldSettings)
    if (thereIsExtraField) {
      schema.push(extraFieldSettings)
      thereIsExtraField = false
    }
  })

  return schema
}

const getFormData = async () => {
  storeParams.value = {
    type: 'forms',
    id: props.data.form,
    showLoading: true
  }
  await store.dispatch('getSingleById', storeParams.value)
  form.value = store.getters.singleById(storeParams.value)
  // eslint-disable-next-line no-prototype-builtins
  isFormValid.value = form.value.hasOwnProperty('fields')
  if (!isFormValid.value) {
    return
  }
  schema.value = setFormSchema()
  isDataFormLoaded.value = true

  // console.log(form)
  // console.log(schema)
}

onBeforeMount(() => {
  // console.log('data', props)
  getFormData()
})
</script>
<style lang="scss" scoped>
.contact-section {
  @apply bg-curie-dark-gray w-full min-h-screen;

  .inner-wrapper {
    @apply container justify-center flex flex-col py-xl lg:p-xl;
    .text-wrapper {
      @apply w-full lg:px-xl;

      &:deep(*){
        @apply text-curie-light-gray;
      }

      &:deep(.contact-title) {
        @apply mb-lg text-center;
      }

      &:deep(ol){
        li {
          @apply font-FKGrotesk text-lg leading-6;
        }
      }
    }
    .form-wrapper {
      @apply w-full;
      .messages-wrapper {
        @apply mb-6;
        .title {
          @apply text-curie-light-gray mb-1;
        }
        .validation-errors {
          @apply my-4 list-disc pl-6;
          li {
            @apply text-xs text-curie-light-gray;
          }
        }
      }
      &:deep(.formkit-form) {
        .formkit-outer {
          @apply mb-2;
          *{
            @apply text-curie-dark-gray font-FKGrotesk;
          }
          .formkit-wrapper {
            .formkit-inner {
              @apply mb-1;
              input{
                &:focus{
                  @apply outline-none;
                }
                &:focus-visible{
                  @apply outline-none;
                }
                &::placeholder{
                  @apply text-curie-dark-gray;
                }
              }
              input, textarea {
                &:focus {
                  @apply outline-none;
                }
              }
              input[type="text"], input[type="email"], textarea{
                @apply bg-white rounded-base p-4 lg:py-6 w-full text-[22px] leading-7;
              }
              input[type="file"]{
                @apply text-transparent flex items-center justify-center pl-2 lg:p-0 leading-none pb-0;

                &::-webkit-file-upload-button {
                  @apply invisible;
                }

                &::before {
                  -webkit-user-select: none;
                  @apply outline-none whitespace-nowrap cursor-pointer py-1 lg:p-4 w-9 content-[url('@/assets/img/attachment.svg')];
                }
              }
              input[type="radio"]{
                @apply bg-cover lg:mr-4;
                background-image: url('@/assets/img/radio-unchecked.svg');
              }
              input[type="radio"]:checked{
                background-image: url('@/assets/img/radio-checked.svg');
              }
            }
          }
          .formkit-help {
            @apply text-sm leading-[15px];
          }
          .formkit-messages{
            @apply my-1;
            .formkit-message {
              @apply text-xs text-curie-light-gray;
            }
          }

          &.full-label {
            @apply bg-white rounded-base p-4 lg:py-6 w-full;
            .formkit-legend {
              @apply text-[22px] leading-7 mb-5;;
            }
          }

          &[data-type="radio"], &[data-type="checkbox"] {
            .formkit-options {
              @apply flex flex-col flex-wrap lg:flex-row gap-x-16;
              .formkit-option{
                @apply flex flex-row;
                .formkit-wrapper {
                  @apply flex flex-row items-center gap-x-[20px] cursor-pointer;
                  .formkit-label {
                    @apply text-sm leading-[15px];
                  }
                }
              }
            }
          }

          &[data-type="file"] {
            .formkit-wrapper {
              .formkit-label {
                @apply text-[22px] leading-7 mb-6 pl-12 cursor-pointer;
              }
              .formkit-inner {
                @apply relative;
                input{
                  @apply absolute -top-2 lg:-top-4 md:top-0 -left-4 w-14;
                }
                .formkit-file-list {
                  @apply pl-12;
                  .formkit-file-item {
                    @apply flex flex-row items-center gap-x-3;
                    .formkit-file-name {
                      @apply text-sm text-curie-med-gray;
                    }
                    .formkit-file-remove {
                      @apply text-transparent;
                      &:before{
                        @apply content-['X'] text-red-300;
                      }
                      &:hover{
                        &:before{
                          @apply text-red-500;
                        }
                      }
                    }
                  }
                }
                .formkit-no-files {
                  @apply pl-12 cursor-pointer text-sm text-curie-med-gray;;
                }
              }

              &:hover {
                .formkit-label {
                  @apply text-curie-orange;
                }
              }
            }
            .formkit-help {
              @apply mt-4 pl-12;
            }
            .formkit-messages {
              @apply pl-12;
            }
          }

          .input-section {
            @apply text-curie-light-gray p-4 mb-0 text-[22px] leading-7;
          }
        }
        .formkit-actions {
          .formkit-outer {
            .formkit-wrapper {
              @apply w-auto relative z-1 rounded-base flex justify-center border-curie-orange bg-curie-orange;
              .formkit-input[type="submit"] {
                @apply flex justify-center gap-5 w-full py-[25px] px-14
                  font-FKGrotesk font-bold text-[22px] leading-7
                  transition-colors ease-linear duration-500 text-curie-dark-gray;

                  &::after {
                    @apply content-[url('@/assets/img/arrow.svg')] pt-0.5;
                  }
              }
              &::before {
                @apply absolute content-[""] top-0 right-0 bottom-0 left-0 -z-1 transition-opacity duration-500 ease-linear opacity-0 curie-gradient rounded-base;
              }
              &:hover {
                .formkit-input[type="submit"] {
                  @apply text-white;
                  &::after {
                    @apply content-[url('@/assets/img/arrow-white.svg')];
                  }
                }
                &::before {
                  @apply opacity-100;
                }
              }
            }
          }
        }
        .formkit-messages {
          @apply my-4;
          .formkit-message {
            @apply text-curie-light-gray text-lg;
          }
        }
      }
    }
  }
}
</style>