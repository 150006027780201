<template>
  <nav>
    <ul class="nav-container">
      <template v-for="item in menu">
        <li
          v-if="item.parent === '0'"
          :key="item.id"
          :title="item.title"
          :class="item.class"
        >
          <a
            :href="$root.convertToRelative(item.url)"
            v-html="item.content"
            :target="item.target"
          >
          </a>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script setup>
import { useStore } from 'vuex'
const store = useStore()
const menu = store.getters.menu({ name: 'footer-menu' })
</script>

<style lang="scss" scoped>
.nav-container {
  @apply w-full grid grid-cols-1 md:grid-cols-2 gap-y-5 mt-10;
  li {
    @apply text-curie-dark-gray text-lg leading-[22px] font-normal;
  }
}
</style>
