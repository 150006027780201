<template>
  <section class="item-list">
    <div class="title-wrapper">
      <h3 @click="toggle = !toggle">{{ title }}</h3>
      <img
        src="@/assets/img/arrow-down-white.svg"
        class="arrow"
        :class="
          active
            ? 'transform rotate-180 transition-all duration-300 ease-in-out'
            : 'transform rotate-0 transition-all duration-300 ease-in-out'
        "
        @click="toggle = !toggle"
      />
    </div>
    <slide-up-down v-model="active" :duration="300">
      <div class="text-wrapper" v-html="text"></div>
    </slide-up-down>
  </section>
</template>
<script setup>
import {
  defineProps,
  toRefs,
  ref,
  computed,
  onBeforeMount,
  onUnmounted
} from 'vue'
import SlideUpDown from 'vue3-slide-up-down'

const props = defineProps({
  title: String,
  text: String,
  isActive: Boolean
})
const { title, text, isActive = false } = toRefs(props)
const isDesktop = ref(window.innerWidth >= 1024)
const toggle = ref(isActive.value)
const active = computed(() => {
  if (isDesktop.value) {
    return true
  }
  return toggle.value
})

function onResizeWindow () {
  isDesktop.value = window.innerWidth >= 1024
}

onBeforeMount(() => {
  onResizeWindow()
  window.addEventListener('resize', onResizeWindow)
})

onUnmounted(() => {
  onResizeWindow()
  window.removeEventListener('resize', onResizeWindow)
})
</script>
<style lang="scss" scoped>
.item-list:deep() {
  @apply bg-white;
  .title-wrapper {
    @apply mb-0 flex flex-row items-center justify-between;
    h3 {
      @apply w-10/12 lg:w-full;
    }
    .arrow {
      @apply cursor-pointer filter invert lg:hidden transition-transform duration-300 ease-in-out;
    }
  }
  .slide-up-down__container {
    @apply lg:h-fit  #{!important};
  }
  .text-wrapper {
    @apply mt-sm;
  }
}
</style>
