function _defineProperties (e, t) {
  for (let r = 0; r < t.length; r++) {
    const n = t[r];
    (n.enumerable = n.enumerable || !1), (n.configurable = !0), 'value' in n && (n.writable = !0), Object.defineProperty(e, n.key, n)
  }
}

function s () {
  return typeof window !== 'undefined'
}

function t () {
  return I || (s() && (I = window.gsap) && I.registerPlugin && I)
}

let I
let z
let N
let W
let B
let F
let U
let Q
let V
let K
let j
let q
let D
let Y
let X
const r =
    ((typeof window !== 'undefined' ? window.location.host : ''),
    (ScrollSmoother.register = function register (e) {
      return (
        z ||
          ((I = e || t()),
          s() && window.document && ((N = window), (W = document), (B = W.documentElement), (F = W.body)),
          I &&
            ((U = I.utils.toArray),
            (Q = I.utils.clamp),
            (j = I.parseEase('expo')),
            (Y = I.core.context || function () {}),
            (X = I.delayedCall(0.2, function () {
              return V.isRefreshing || (K && K.refresh())
            }).pause()),
            (V = I.core.globals().ScrollTrigger),
            I.core.globals('ScrollSmoother', ScrollSmoother),
            F && V && ((q = V.core._getVelocityProp), (D = V.core._inputObserver), (ScrollSmoother.refresh = V.refresh), (z = 1)))),
        z
      )
    }),
    (function _createClass (e, t, r) {
      return t && _defineProperties(e.prototype, t), r && _defineProperties(e, r), e
    })(ScrollSmoother, [{
      key: 'progress',
      get: function get () {
        return this.scrollTrigger ? this.scrollTrigger.animation._time / 100 : 0
      }
    }]),
    ScrollSmoother)

function ScrollSmoother (e) {
  const o = this
  z || ScrollSmoother.register(I) || console.warn('Please gsap.registerPlugin(ScrollSmoother)'), (e = this.vars = e || {}), K && K.kill(), Y((K = this))

  function Ia () {
    return A.update(-P)
  }

  function Ka () {
    return (n.style.overflow = 'visible')
  }

  function Ma (e) {
    e.update()
    const t = e.getTween()
    t && (t.pause(), (t._time = t._dur), (t._tTime = t._tDur)), (d = !1), e.animation.progress(e.progress, !0)
  }

  function Na (e, t) {
    ((e !== P && !u) || t) && (C && ((n.style.transform = 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ' + e + ', 0, 1)'), (n._gsap.y = e + 'px')), (M = e - P), (P = e), V.isUpdating || V.update())
  }

  function Oa (e) {
    return arguments.length ? (e < 0 && (e = 0), (H.y = -e), (d = !0), u ? (P = -e) : Na(-e), V.isRefreshing ? i.update() : _(e), this) : -P
  }

  function Qa (e) {
    (m.scrollTop = 0), (e.target.contains && e.target.contains(m)) || (T && !1 === T(o, e)) || (V.isInViewport(e.target) || e.target === g || o.scrollTo(e.target, !1, 'center center'), (g = e.target))
  }

  function Ra (e) {
    let r, n, o, i
    v.forEach(function (t) {
      (r = t.pins),
      (i = t.markers),
      e.forEach(function (e) {
        t.trigger &&
            e.trigger &&
            t !== e &&
            (e.trigger === t.trigger || e.pinnedContainer === t.trigger || t.trigger.contains(e.trigger)) &&
            ((n = e.start),
            (o = (n - t.start - t.offset) / t.ratio - (n - t.start)),
            r.forEach(function (e) {
              return (o -= e.distance / t.ratio - e.distance)
            }),
            e.setPositions(n + o, e.end + o),
            e.markerStart && i.push(I.quickSetter([e.markerStart, e.markerEnd], 'y', 'px')),
            e.pin && e.end > 0 && ((o = e.end - e.start), r.push({
              start: e.start,
              end: e.end,
              distance: o,
              trig: e
            }), t.setPositions(t.start, t.end + o), t.vars.onRefresh(t)))
      })
    })
  }

  function Sa () {
    Ka(),
    requestAnimationFrame(Ka),
    v &&
        (v.forEach(function (e) {
          let t = e.start
          let r = e.auto ? Math.min(V.maxScroll(e.scroller), e.end) : t + (e.end - t) / e.ratio
          const n = (r - e.end) / 2;
          (t -= n), (r -= n), (e.offset = n || 1e-4), (e.pins.length = 0), e.setPositions(Math.min(t, r), Math.max(t, r)), e.vars.onRefresh(e)
        }),
        Ra(V.sort())),
    A.reset()
  }

  function Ta () {
    return V.addEventListener('refresh', Sa)
  }

  function Ua () {
    return (
      v &&
        v.forEach(function (e) {
          return e.vars.onRefresh(e)
        })
    )
  }

  function Va () {
    return (
      v &&
        v.forEach(function (e) {
          return e.vars.onRefreshInit(e)
        }),
      Ua
    )
  }

  function Wa (t, r, n, o) {
    return function () {
      let e = typeof r === 'function' ? r(n, o) : r
      return e || e === 0 || (e = o.getAttribute('data-' + k + t) || (t === 'speed' ? 1 : 0)), o.setAttribute('data-' + k + t, e), e === 'auto' ? e : parseFloat(e)
    }
  }

  function Xa (r, e, t, n) {
    function Lb () {
      (e = s()), (t = f()), (o = parseFloat(e) || 1), (c = (a = e === 'auto') ? 0 : 0.5), l && l.kill(), (l = t && I.to(r, {
        ease: j,
        overwrite: !1,
        y: '+=0',
        duration: t
      })), i && ((i.ratio = o), (i.autoSpeed = a))
    }

    function Mb () {
      (d.y = h + 'px'), d.renderTransform(1), Lb()
    }

    function Qb (e) {
      if (a) {
        Mb()
        const t = (function _autoDistance (e, t) {
          let r
          let n
          const o = e.parentNode || B
          const i = e.getBoundingClientRect()
          const s = o.getBoundingClientRect()
          const a = s.top - i.top
          const l = s.bottom - i.bottom
          let c = (Math.abs(a) > Math.abs(l) ? a : l) / (1 - t)
          let u = -c * t
          return c > 0 && ((n = (r = s.height / (N.innerHeight + s.height)) == 0.5 ? 2 * s.height : 2 * Math.min(s.height, (-c * r) / (2 * r - 1)) * (t || 1)), (u += t ? -n * t : -n / 2), (c += n)), {
            change: c,
            offset: u
          }
        })(r, Q(0, 1, -e.start / (e.end - e.start)));
        (w = t.change), (u = t.offset)
      } else (w = (e.end - e.start) * (1 - o)), (u = 0)
      g.forEach(function (e) {
        return (w -= e.distance * (1 - o))
      }),
      e.vars.onUpdate(e),
      l && l.progress(1)
    }
    let o
    let i
    let a
    let l
    let c
    let u
    var s = Wa('speed', e, n, r)
    var f = Wa('lag', t, n, r)
    var h = I.getProperty(r, 'y')
    var d = r._gsap
    var g = []
    const p = []
    var w = 0
    return (
      Lb(),
      (o !== 1 || a || l) &&
        (Qb(
          (i = V.create({
            trigger: a ? r.parentNode : r,
            scroller: m,
            scrub: !0,
            refreshPriority: -999,
            onRefreshInit: Mb,
            onRefresh: Qb,
            onKill: function onKill (e) {
              const t = v.indexOf(e)
              t >= 0 && v.splice(t, 1), Mb()
            },
            onUpdate: function onUpdate (e) {
              let t
              let r
              let n
              let o = h + w * (e.progress - c)
              let i = g.length
              let s = 0
              if (e.offset) {
                if (i) {
                  for (r = -P, n = e.end; i--;) {
                    if ((t = g[i]).trig.isActive || (r >= t.start && r <= t.end)) { return void (l && ((t.trig.progress += t.trig.direction < 0 ? 0.001 : -0.001), t.trig.update(0, 0, 1), l.resetTo('y', parseFloat(d.y), -M, !0), O && l.progress(1))) }
                    r > t.end && (s += t.distance), (n -= t.distance)
                  }
                  o = h + s + w * ((I.utils.clamp(e.start, e.end, r) - e.start - s) / (n - e.start) - c)
                }
                (o = (function _round (e) {
                  return Math.round(1e5 * e) / 1e5 || 0
                })(o + u)),
                p.length &&
                    !a &&
                    p.forEach(function (e) {
                      return e(o - s)
                    }),
                l ? (l.resetTo('y', o, -M, !0), O && l.progress(1)) : ((d.y = o + 'px'), d.renderTransform(1))
              }
            }
          }))
        ),
        (I.core.getCache(i.trigger).stRevert = Va),
        (i.startY = h),
        (i.pins = g),
        (i.markers = p),
        (i.ratio = o),
        (i.autoSpeed = a),
        (r.style.willChange = 'transform')),
      i
    )
  }
  let n
  let m
  let t
  let i
  let v
  let s
  let a
  let l
  let c
  let u
  let r
  let f
  let h
  let d
  let g
  const p = e.smoothTouch
  const w = e.onUpdate
  const S = e.onStop
  const b = e.smooth
  var T = e.onFocusIn
  const x = e.normalizeScroll
  const E = this
  const R =
      typeof ResizeObserver !== 'undefined' &&
      !1 !== e.autoResize &&
      new ResizeObserver(function () {
        return V.isRefreshing || X.restart(!0)
      })
  var k = e.effectsPrefix || ''
  var _ = V.getScrollFunc(N)
  var C = V.isTouch === 1 ? (!0 === p ? 0.8 : parseFloat(p) || 0) : b === 0 || !1 === b ? 0 : parseFloat(b) || 0.8
  var P = 0
  var M = 0
  var O = 1
  var A = q(0)
  var H = {
    y: 0
  }

  function refreshHeight () {
    return (t = n.clientHeight), (n.style.overflow = 'visible'), (F.style.height = t + 'px'), t - N.innerHeight
  }
  Ta(),
  V.addEventListener('killAll', Ta),
  I.delayedCall(0.5, function () {
    return (O = 0)
  }),
  (this.scrollTop = Oa),
  (this.scrollTo = function (e, t, r) {
    const n = I.utils.clamp(0, V.maxScroll(N), isNaN(e) ? o.offset(e, r) : +e)
    t
      ? (u
          ? I.to(o, {
            duration: C,
            scrollTop: n,
            overwrite: 'auto',
            ease: j
          })
          : _(n))
      : Oa(n)
  }),
  (this.offset = function (e, t) {
    let r
    const n = (e = U(e)[0]).style.cssText
    const o = V.create({
      trigger: e,
      start: t || 'top top'
    })
    return v && Ra([o]), (r = o.start), o.kill(!1), (e.style.cssText = n), (I.core.getCache(e).uncache = 1), r
  }),
  (this.content = function (e) {
    if (arguments.length) {
      const t = U(e || '#smooth-content')[0] || console.warn('ScrollSmoother needs a valid content element.') || F.children[0]
      return t !== n && ((c = (n = t).getAttribute('style') || ''), R && R.observe(n), I.set(n, {
        overflow: 'visible',
        width: '100%',
        boxSizing: 'border-box',
        y: '+=0'
      }), C || I.set(n, {
        clearProps: 'transform'
      })), this
    }
    return n
  }),
  (this.wrapper = function (e) {
    return arguments.length
      ? ((m =
              U(e || '#smooth-wrapper')[0] ||
              (function _wrap (e) {
                let t = W.querySelector('.ScrollSmoother-wrapper')
                return t || ((t = W.createElement('div')).classList.add('ScrollSmoother-wrapper'), e.parentNode.insertBefore(t, e), t.appendChild(e)), t
              })(n)),
        (l = m.getAttribute('style') || ''),
        refreshHeight(),
        I.set(
          m,
          C
            ? {
                overflow: 'hidden',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
              }
            : {
                overflow: 'visible',
                position: 'relative',
                width: '100%',
                height: 'auto',
                top: 'auto',
                bottom: 'auto',
                left: 'auto',
                right: 'auto'
              }
        ),
        this)
      : m
  }),
  (this.effects = function (e, t) {
    if (((v = v || []), !e)) return v.slice(0);
    (e = U(e)).forEach(function (e) {
      for (let t = v.length; t--;) v[t].trigger === e && v[t].kill()
    })
    t = t || {}
    let r
    let n
    const o = t.speed
    const i = t.lag
    const s = []
    for (r = 0; r < e.length; r++)(n = Xa(e[r], o, i, r)) && s.push(n)
    return v.push.apply(v, s), s
  }),
  (this.sections = function (e, t) {
    if (((s = s || []), !e)) return s.slice(0)
    const r = U(e).map(function (t) {
      return V.create({
        trigger: t,
        start: 'top 120%',
        end: 'bottom -20%',
        onToggle: function onToggle (e) {
          (t.style.opacity = e.isActive ? '1' : '0'), (t.style.pointerEvents = e.isActive ? 'all' : 'none')
        }
      })
    })
    return t && t.add ? s.push.apply(s, r) : (s = r.slice(0)), r
  }),
  this.content(e.content),
  this.wrapper(e.wrapper),
  (this.render = function (e) {
    return Na(e || e === 0 ? e : P)
  }),
  (this.getVelocity = function () {
    return A.getVelocity(-P)
  }),
  V.scrollerProxy(m, {
    scrollTop: Oa,
    scrollHeight: function scrollHeight () {
      return refreshHeight() && F.scrollHeight
    },
    fixedMarkers: !1 !== e.fixedMarkers && !!C,
    content: n,
    getBoundingClientRect: function getBoundingClientRect () {
      return {
        top: 0,
        left: 0,
        width: N.innerWidth,
        height: N.innerHeight
      }
    }
  }),
  V.defaults({
    scroller: m
  })
  const L = V.getAll().filter(function (e) {
    return e.scroller === N || e.scroller === m
  })
  L.forEach(function (e) {
    return e.revert(!0)
  }),
  (i = V.create({
    animation: I.fromTo(
      H, {
        y: 0
      }, {
        y: function y () {
          return -refreshHeight()
        },
        immediateRender: !1,
        ease: 'none',
        data: 'ScrollSmoother',
        duration: 100,
        onUpdate: function onUpdate () {
          if (this._dur) {
            const e = d
            e && (Ma(i), (H.y = P)), Na(H.y, e), Ia(), w && !u && w(E)
          }
        }
      }
    ),
    onRefreshInit: function onRefreshInit (e) {
      if (v) {
        const t = V.getAll().filter(function (e) {
          return !!e.pin
        })
        v.forEach(function (r) {
          r.vars.pinnedContainer ||
                t.forEach(function (e) {
                  if (e.pin.contains(r.trigger)) {
                    const t = r.vars;
                    (t.pinnedContainer = e.pin), (r.vars = null), r.init(t, r.animation)
                  }
                })
        })
      }
      const r = e.getTween();
      (h = r && r._end > r._dp._time),
      (f = P),
      (H.y = 0),
      C &&
            ((m.style.pointerEvents = 'none'),
            (m.scrollTop = 0),
            setTimeout(function () {
              return m.style.removeProperty('pointer-events')
            }, 50))
    },
    onRefresh: function onRefresh (e) {
      e.animation.invalidate(), e.setPositions(e.start, refreshHeight()), h || Ma(e), (H.y = -_()), Na(H.y), O || e.animation.progress(I.utils.clamp(0, 1, f / -e.end)), h && ((e.progress -= 0.001), e.update())
    },
    id: 'ScrollSmoother',
    scroller: N,
    invalidateOnRefresh: !0,
    start: 0,
    refreshPriority: -9999,
    end: refreshHeight,
    onScrubComplete: function onScrubComplete () {
      A.reset(), S && S(o)
    },
    scrub: C || !0
  })),
  (this.smooth = function (e) {
    return arguments.length && (C = e || 0), arguments.length ? i.scrubDuration(e) : i.getTween() ? i.getTween().duration() : 0
  }),
  i.getTween() && (i.getTween().vars.ease = e.ease || j),
  (this.scrollTrigger = i),
  e.effects && this.effects(!0 === e.effects ? '[data-' + k + 'speed], [data-' + k + 'lag]' : e.effects, {}),
  e.sections && this.sections(!0 === e.sections ? '[data-section]' : e.sections),
  L.forEach(function (e) {
    (e.vars.scroller = m), e.init(e.vars, e.animation)
  }),
  (this.paused = function (e, t) {
    return arguments.length
      ? (!!u !== e &&
            (e
              ? (i.getTween() && i.getTween().pause(),
                _(-P),
                A.reset(),
                (r = V.normalizeScroll()) && r.disable(),
                ((u = V.observe({
                  preventDefault: !0,
                  type: 'wheel,touch,scroll',
                  debounce: !1,
                  allowClicks: !0,
                  onChangeY: function onChangeY () {
                    return Oa(-P)
                  }
                })).nested = D(B, 'wheel,touch,scroll', !0, !1 !== t)))
              : (u.nested.kill(), u.kill(), (u = 0), r && r.enable(), (i.progress = (-P - i.start) / (i.end - i.start)), Ma(i))),
        this)
      : !!u
  }),
  (this.kill = this.revert = function () {
    o.paused(!1), Ma(i), i.kill()
    for (let e = (v || []).concat(s || []), t = e.length; t--;) e[t].kill()
    V.scrollerProxy(m), V.removeEventListener('killAll', Ta), V.removeEventListener('refresh', Sa), (m.style.cssText = l), (n.style.cssText = c)
    const r = V.defaults({})
    r && r.scroller === m && V.defaults({
      scroller: N
    }), o.normalizer && V.normalizeScroll(!1), clearInterval(a), (K = null), R && R.disconnect(), F.style.removeProperty('height'), N.removeEventListener('focusin', Qa)
  }),
  (this.refresh = function (e, t) {
    return i.refresh(e, t)
  }),
  x && (this.normalizer = V.normalizeScroll(!0 === x
    ? {
        debounce: !0,
        content: !C && n
      }
    : x)),
  V.config(e),
  'overscrollBehavior' in N.getComputedStyle(F) && I.set([F, B], {
    overscrollBehavior: 'none'
  }),
  'scrollBehavior' in N.getComputedStyle(F) && I.set([F, B], {
    scrollBehavior: 'auto'
  }),
  N.addEventListener('focusin', Qa),
  (a = setInterval(Ia, 250)),
  W.readyState === 'loading' ||
      requestAnimationFrame(function () {
        return V.refresh()
      })
}
r.version = '3.11.3'
r.create = function (e) {
  return K && e && K.content() === U(e.content)[0] ? K : new r(e)
}
r.get = function () {
  return K
}

export { ScrollSmoother }
