<template>
  <section class="opening-positions" id="opening-positions">
    <div class="top">
      <h3 class="title">{{ props.data.title }}</h3>
    </div>
    <div class="filter-wrapper">
      <div class="filter">
        <div v-for="(fliter, indx) of categoryFilter" :key="indx" @click="setActive(indx)" :class="`filter-item ${indx === active ? 'active' : ''}`">
        <h4 class="title">
          {{ fliter.location.title }}
        </h4>
      </div>
    </div>
    </div>
    <div class="list" v-if="careers && careers.length !== 0">
      <div
        class="position"
        v-for="career in careers.slice(0, careersToShow)"
        :key="career.id"
      >
        <div class="general">
          <span class="title">{{ career.title }}</span>
          <span class="department">
            <div v-if="!career.metadata[0].value">
              <span
              v-for="(dept, index) of career.departments"
              :key="`departments-${index}`"
            >
              {{
                career.departments.length === index + 1
                  ? `${dept.name}`
                  : `${dept.name}, `
              }}
            </span>
            </div>
          </span>
          <span class="time">
            <span
              v-for="(office, ind) of career.offices"
              :key="`office-${ind}`"
            >
              {{
                career.offices.length === ind + 1
                  ? `${office.name}`
                  : `${office.name}`
              }}
            </span>
          </span>
        </div>
        <div
          v-if="career.metadata[0].value"
          class="description line-clamp-4"
          v-html="parseHTMLString(career.content)"
        ></div>
        <div class="cta">
          <Button
            :url="career.absolute_url"
            target="_blank"
            text="See More"
            :icon-visible="true"
            color="transparent-dark-gray"
            width="full"
          />
        </div>
      </div>
    </div>
    <div class="list text-center" v-else>
      <h2>No jobs currently. Check back later.</h2>
    </div>
    <div class="bottom" v-if="showCareersBtn">
      <Button
        url="#"
        text="Load More"
        color="orange"
        width="full"
        @click="showMore"
      />
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue'
import Button from '../utility/Button.vue'
import axios from 'axios'

const active = ref(0)
const allCareers = ref([])

const categoryFilter = [
  {
    location: {
      title: 'Curie.Bio Positions'
    }
  },
  {
    location: {
      title: 'Portfolio Company Positions'
    }
  }
]

const setActive = (indx) => {
  active.value = indx
  if (indx === 0) {
    careers.value = allCareers.value.filter((career) => {
      return career.metadata.find(x => x.id === 7104082007).value === null
    })
  } else {
    careers.value = allCareers.value.filter((career) => {
      return career.metadata.find(x => x.id === 7104082007).value !== null
    })
  }
}

const props = defineProps({
  data: Object
})

const careers = ref(null)

const getCareers = () => {
  axios
    .get(
      'https://boards-api.greenhouse.io/v1/boards/curiebiooperationsllc/jobs?content=true'
    )
    .then((response) => {
      allCareers.value = response.data.jobs
      const positions = allCareers.value
      const filteredPositions = positions.filter((career) => {
        return career.metadata.find(x => x.id === 7104082007).value === null
      })
      careers.value = filteredPositions
      // careers.value = positions
    })
    .catch((error) => {
      console.log(error)
    })
}

const parseHTMLString = (htmlString) => {
  const map =
    {
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&quot;': '"',
      '&#039;': "'"
    }
  return htmlString.replace(/&amp;|&lt;|&gt;|&quot;|&#039;/g, (m) => map[m])
}

const careersToShow = ref(15)
const showCareersBtn = computed(() => {
  return careers.value ? !(careersToShow.value >= careers.value.length) : false
})

const showMore = () => {
  careersToShow.value = careersToShow.value + 8
}

onMounted(() => {
  getCareers()
})
</script>

<style lang="scss" scoped>
.opening-positions {
  @apply w-full flex flex-col mb-lg;

  .top {
    @apply container px-0 mb-2;
    .title {
      @apply heading text-white w-full relative md:w-full -left-[15px] rounded-none md:rounded-base md:-left-0;
    }
  }

.filter-wrapper {
  @apply flex justify-center items-center w-full my-12;
  .filter {
    @apply flex flex-col lg:flex-row justify-center items-center mb-2 gap-2;
    @apply bg-curie-light-gray w-fit border-[5px] border-white rounded-base overflow-hidden ;
    .filter-item {
      @apply text-center cursor-pointer;
      @apply w-full lg:w-fit rounded-base overflow-hidden;
      h4 {
        @apply text-curie-blue/50 bg-transparent p-5 min-w-full lg:min-w-[350px] transition-all duration-300 ease-in-out;
      }
      &.active {
        @apply w-full lg:w-fit rounded-base overflow-hidden;
        h4 {
          @apply text-white curie-gradient p-5;
        }
      }
    }
  }
}
  .list {
    @apply w-full lg:inner-container flex flex-col space-y-2 mb-2;
    .position {
      @apply flex flex-col lg:flex-row justify-center items-start lg:items-center bg-white py-6 lg:py-8 px-[15px] lg:px-9 lg:rounded-base;
      &:deep(*) {
        @apply text-curie-dark-gray font-normal;
      }
      .general {
        width: 80%;
        @apply flex flex-col mb-4 lg:self-start;

        @media only screen and (max-width: 767px) {
          width: 100%;
        }
        .title {
          @apply font-bold;
        }
        .company {
          @apply font-bold;
        }
        .department {
          @apply italic;
        }
      }
      .description {
        max-width: 700px;
        margin: auto;
        @apply justify-center items-center lg:pl-8 lg:pr-8;
        &:deep(p:last-of-type) {
          @apply mb-0;
        }

        @media only screen and (max-width: 1023px) {
          max-width: 100%;
        }
      }
      .cta {
        @apply w-full lg:w-auto lg:min-w-[160px] pt-[30px] lg:pt-0;
        .button-wrapper:deep(.button) {
          @apply grow justify-between lg:justify-end px-0 py-0 lg:py-4 font-bold;
        }
      }
    }
  }
  .bottom {
    @apply container lg:inner-container lg:px-0;
  }
}
</style>
