<template>
  <section class="video-module">
    <div class="content-wrapper" @click="toggleVideo()" id="videoIFrame">
      <div
        class="embed-wrapper"
        v-if="videoPlaying && vimeoId"
      >
        <iframe
          :src="videoSource"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          scrolling="no"
        ></iframe>
      </div>
      <template v-if="autoplay">
        <video
          controlsList="nodownload"
          loop
          muted
          autoplay
          playsinline
          preload="auto"
          ref="videoLoop"
          v-show="showVideoLoop"
        >
          <source
            :src="autoplay"
            type="video/mp4"
          />
        </video>
      </template>
    </div>
  </section>
</template>

<script setup>
import { ref, toRefs, computed, onBeforeUnmount } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
  vimeoId: String,
  vimeoHash: String,
  autoplay: String,
  playVideo: Boolean
})
const { vimeoId, vimeoHash, autoplay, playVideo } = toRefs(props)

const showVideoLoop = ref(true)
const videoPlaying = ref(playVideo.value)

const videoSource = computed(() => {
  const hash = vimeoHash.value ? `?h=${vimeoHash.value}` : ''

  return `https://player.vimeo.com/video/${vimeoId.value}${hash}&autoplay=1&title=0&byline=0&portrait=0`
})

const videoLoop = ref(null)
const video = ref(null)

function toggleVideo () {
  if (showVideoLoop.value) {
    if (videoLoop.value) {
      videoLoop.value.pause()
    }
    showVideoLoop.value = false
    videoPlaying.value = true
  } else {
    if (videoLoop.value) {
      videoLoop.value.play()
    }
    showVideoLoop.value = true
    videoPlaying.value = false
  }
}

onBeforeUnmount(() => {
  if (videoLoop.value) {
    videoLoop.value.pause()
  }
  if (video.value) {
    video.value.pause()
  }
})

</script>

<style lang="scss">
.video-module {
  position: relative;
  border-radius: 27px;
  overflow: hidden;

  .content-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    video {
      width: 100%;
    }

    cursor: pointer;
    .embed-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>
