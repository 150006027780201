<template>
  <section class="item-list">
    <div class="title-wrapper">
      <h3>{{ title }}</h3>
      <span
        class="arrow"
        :class="
          active
            ? 'transform rotate-180 transition-all duration-300 ease-in-out'
            : 'transform rotate-0 transition-all duration-300 ease-in-out'
        "
        @click="toggle = !toggle"
      ></span>
    </div>
    <slide-up-down v-model="active" :duration="300">
      <div class="text-wrapper" v-html="text"></div>
    </slide-up-down>
  </section>
</template>
<script setup>
import {
  defineProps,
  toRefs,
  ref,
  computed,
  onBeforeMount,
  onUnmounted
} from 'vue'
import SlideUpDown from 'vue3-slide-up-down'

const props = defineProps({
  title: String,
  text: String,
  isActive: Boolean
})
const { title, text, isActive = false } = toRefs(props)
const isDesktop = ref(window.innerWidth >= 1024)
const toggle = ref(isActive.value)
const active = computed(() => {
  if (isDesktop.value) {
    return true
  }
  return toggle.value
})

function onResizeWindow () {
  isDesktop.value = window.innerWidth >= 1024
}

onBeforeMount(() => {
  onResizeWindow()
  window.addEventListener('resize', onResizeWindow)
})

onUnmounted(() => {
  onResizeWindow()
  window.removeEventListener('resize', onResizeWindow)
})
</script>
<style lang="scss" scoped>
.item-list {
  @apply bg-curie-dark-gray px-[15px] py-6 lg:px-[180px] lg:py-[30px] mb-2;
  &:deep(*) {
    @apply text-white;
  }
  .title-wrapper {
    @apply mb-5 flex flex-row items-center justify-between space-x-4;
    .arrow {
      @apply cursor-pointer bg-[url('@/assets/img/arrow-down-white.svg')] w-5 h-5 bg-contain bg-center bg-no-repeat lg:hidden transition-transform duration-300 ease-in-out;
      &:hover {
        @apply scale-125;
      }
    }
  }
}
</style>
