<template>
  <div class="intro" ref="intro">
    <div class="intro-container">
      <div ref="animation" class="intro-animation"></div>
    </div>
  </div>
</template>

<script>
import LottieWeb from 'lottie-web'
import introJson from '@/assets/lottie/intro.json'
import { ScrollSmoother } from '@/assets/js/ScrollSmoother.js'
export default {
  name: 'siteIntro',
  data () {
    return {}
  },
  methods: {
    checkScroll () {
      const instance = ScrollSmoother.get()
      if (instance) {
        instance.kill()
      } else {
        setTimeout(() => {
          this.checkScroll()
        }, 100)
      }
    }
  },
  mounted () {
    this.checkScroll()
    document.documentElement.classList.add('no-overflow')
    const { animation } = this.$refs
    const anim = LottieWeb.loadAnimation({
      container: animation,
      renderer: 'svg',
      autoplay: true,
      loop: false,
      animationData: introJson
    })
    anim.setSpeed(2)
    anim.onComplete = () => {
      document.documentElement.classList.remove('no-overflow')
      this.$root.introComplete()
      this.$root.scrollInstance()
    }
  },
  destroyed () {}
}
</script>

<style lang="scss">
.intro {
  @apply fixed top-0 left-0 w-full h-full z-50 bg-gradient-to-br from-pageLoadingfrom via-pageLoadingVia to-pageLoadingTo backdrop-blur-xl;
  .intro-container {
    @apply left-0 flex items-center justify-center w-[100vw] h-[100vh] bg-cover bg-no-repeat relative top-[-50px];
    .intro-animation {
      @apply w-[60%] h-[60%] md:w-[500px] md:h-[500px] m-auto;
    }
  }
}
</style>
