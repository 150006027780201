<template>
  <section class="toogle-logo-grid">
    <div class="title-wrapper">
      <div class="heading" :class="{'inactive': index !== active, 'not-toggle': props.data.lists.length === 1}" v-for="(list, index) in props.data.lists" :key="`tlg-list-${index}`" @click="changeTab(index)">
        <h3 class="mobile">{{ list.title.mobile }}</h3>
        <h3 class="desktop">{{ list.title.desktop }}</h3>
      </div>
    </div>
    <div class="content-wrapper">
      <div class="inner-wrapper">
        <transition-group name="fade" mode="out-in" appear :duration="300">
          <template v-for="(list2, indexList2) in props.data.lists">
            <div v-if="active === indexList2 && list2.description" class="description" v-html="list2.description" :key="`tlg-description-${indexList2}`"></div>
            <div v-if="active === indexList2" :class="list2.type_of_items" :key="`tlg-images-${indexList2}`">
              <template v-if="list2.type_of_items === 'images'">
                <div class="image" :class="`image-${indexImage}`" v-for="(image, indexImage) in contentList.list_of_images" :key="`tlg-image-${indexImage}`">
                  <img
                    :src="image.image.url"
                    :alt="image.image.alt"/>
                </div>
              </template>
              <template v-if="list2.type_of_items == 'cards'">
                <div class="card" :class="card.stage" v-for="(card, indexCard) in contentList.cards" :key="`tlg-image-${indexCard}`">
                  <div class="title" v-html="card.title"></div>
                </div>
              </template>
            </div>
          </template>
        </transition-group>
      </div>
    </div>
  </section>
</template>

<script setup>
import { defineProps, ref, computed, onUpdated, watch } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
  data: Object
})
const active = ref(0)
const contentList = computed(() => {
  return props.data.lists[active.value]
})
const timer = ref(null)
const animationStarted = ref(false)

watch(active, (_active, _prevActive) => {
  startAnimation(_active)
})

const changeTab = (index) => {
  active.value = index
}

const getRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const animateLogo = () => {
  const animation = gsap.timeline({ repeat: 0 })
  const currentIndex = getRandomInt(0, contentList.value.list_of_images.length - 1)
  const currentElement = `.image-${currentIndex}`
  animation.fromTo(currentElement, { opacity: 0.1 }, { opacity: 1, duration: 10 })
}

const endAnimation = () => {
  clearInterval(timer.value)
  animationStarted.value = false
}

const startAnimation = () => {
  if (contentList.value.type_of_items === 'images') {
    if (animationStarted.value) {
      return
    }
    animationStarted.value = true
    timer.value = setInterval(() => {
      animateLogo()
    }, 2000)
  } else {
    endAnimation()
  }
}

onUpdated(() => {
  ScrollTrigger.refresh()
})

// onMounted(() => {
//   startAnimation()
// })

// onBeforeUnmount(() => {
//   endAnimation()
// })
// console.log(props)
</script>

<style lang="scss" scoped>
.toogle-logo-grid {
  @apply w-full flex flex-col mb-lg;

  .title-wrapper {
    @apply container flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2 mb-2;
    .heading {
      @apply container cursor-pointer toggle-heading w-full text-center;

      &.not-toggle {
        @apply text-left cursor-auto;
      }

      h3 {
        @apply text-white capitalize;
        &.mobile{
          @apply lg:hidden;
        }
        &.desktop{
          @apply hidden lg:block;
        }
      }
    }
  }

  .content-wrapper {
    @apply bg-white lg:bg-transparent container;
    .inner-wrapper {
      @apply lg:bg-white w-full flex flex-row flex-wrap justify-center items-center rounded-base;
      .description {
        @apply text-center pt-16 pb-8 px-8;
        &:deep(a) {
          @apply underline;
          :hover {
            @apply text-curie-blue;
          }
          &:hover {
            @apply text-curie-blue;
          }
        }
      }
      .images {
        @apply bg-transparent w-full flex flex-row flex-wrap mt-sm;

        .image {
          @apply flex-2 lg:flex-4 flex items-center justify-center py-4 lg:py-8 px-2 lg:px-4;
          img{
            @apply max-w-full;
          }
        }
      }

      .cards {
        @apply bg-transparent w-full flex flex-row flex-wrap mt-sm justify-center items-center gap-x-3 gap-y-6;
        margin-bottom: 54px;
        .card {
          @apply bg-white border shadow-sm rounded-base w-[46%] lg:w-[28%] xl:w-[20%] flex flex-col items-start justify-center;
          border-color: #EEEEEE;
          padding: 4px 11px 8px;
          &:before {
            @apply content-[""] w-full bg-curie-med-gray mb-1;
            height: 12px;
            border-radius: 37px;
          }
          &.approved {
            &:before {
              @apply bg-curie-blue;
            }
          }

          &.phase3 {
            &:before {
              @apply bg-curie-purple;
            }
          }
          &.phase2 {
            &:before {
              @apply bg-curie-pink;
            }
          }
          &.phase1 {
            &:before {
              @apply bg-curie-orange;
            }
          }
          .title {
            @apply font-FKGrotesk font-bold text-lg leading-6 text-curie-dark-gray;
            letter-spacing: -0.04em;
          }
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  & > * {
    transition-duration: 200ms;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(.6,.15,.35,.8);
  }
}

$delay: 100ms;
$delayStep: 100ms;

.fade-enter,
.fade-leave-to {
  & > * {
    opacity: 0;
    transform: translateY(40px);
  }
}
.fade-enter-active {
  & > * {
    &:nth-child(2) {
      transition-delay: $delay;
    }
    &:nth-child(3) {
      transition-delay: $delay + $delayStep;
    }
  }
}
.fade-leave-active {
  & > * {
    &:nth-child(1) {
      transition-delay: $delay + $delayStep;
    }
    &:nth-child(2) {
      transition-delay: $delay;
    }
  }
}
</style>
