import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import emitter from '../composables/useEmitter'
import VueScrollTo from 'vue-scrollto'
import store from '../store'

const { url } = __VUE_WORDPRESS__.routing
// scroll position is handled in @after-leave transition hook
if ('scrollRestoration' in window.history) window.history.scrollRestoration = 'manual'

export const router = createRouter({
  history: createWebHistory(url.replace(window.location.origin, '')),
  routes,
  scrollBehavior (to, from, savedPosition) {
    const position = { left: 0, top: 0 }
    if (to.hash) {
      const options = {
        container: 'body',
        easing: 'ease-in-out',
        lazy: false,
        offset: -90,
        force: true,
        cancelable: false,
        x: false,
        y: true
      }
      const navigate = () => {
        if (store.state.site.loading) {
          setTimeout(() => {
            navigate()
          }, 500)
        } else {
          VueScrollTo.scrollTo(to.hash, 500, options)
        }
      }

      navigate()
    }

    if (savedPosition) {
      position.x = savedPosition.x
      position.y = savedPosition.y

      return new Promise(resolve => {
        emitter.on('scrollBeforeEnter', (done) => {
          resolve(position)
          done()
        })
      })
    } else {
      return new Promise(resolve => {
        emitter.on('scrollBeforeEnter', (done) => {
          resolve(position)
          done()
        })
      })
    }
    // Only way I could see to go to an anchor on another page. Possible race condition.

    // if (savedPosition) {
    //   position.x = savedPosition.x
    //   position.y = savedPosition.y

    //   return new Promise(resolve => {
    //     emitterInstance().on('scrollBeforeEnter', () => {
    //       resolve(position)
    //     })
    //   })
    // } else {
    // return new Promise(resolve => {
    //   emitter.on('scrollBeforeEnter', (done) => {
    //     resolve(position)
    //     done()
    //   })
    // })
    // }
  }
})
