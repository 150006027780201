<template>
  <section class="flexible-content">
    <div
      class="item"
      v-for="(item, index) in props.data.content"
      :key="`item-content-${index}`"
      :class="[`item-type-${item.type_of_content}`]"
      :style="item.type_of_content !== 'title' ? css : {}"
    >
      <Button
        v-if="item.type_of_content === 'cta'"
        :url="item.call_to_action.url"
        :target="item.call_to_action.target"
        :text="item.call_to_action.title"
        :color="`orange`"
        :icon-visible="true"
      />
      <img
        v-if="item.type_of_content === 'image'"
        :src="item.image.url || item.image.guid"
        :alt="item.image.alt"
      />
      <div
        v-if="
          item.type_of_content === 'text' || item.type_of_content === 'title'
        "
        v-html="item.text"
        :class="{ 'heading': item.type_of_content === 'title', 'text': item.type_of_content === 'text'}"
      ></div>
      <template
        v-if="item.type_of_content === 'list'"
      >
        <ItemList
          v-for="(itemList, indexList) in item.list"
          :key="`item-list-${indexList}`" :text="itemList.text" :title="itemList.title" :isActive="indexList === 0"/>
      </template>
    </div>
  </section>
</template>

<script setup>
import { defineProps, ref, watch, onBeforeMount, onUnmounted } from 'vue'
import Button from '@/components/utility/Button.vue'
import ItemList from './ItemList.vue'

const props = defineProps({
  data: Object
})
const isDesktop = ref(false)
const css = ref({})

function onResizeWindow () {
  isDesktop.value = (window.innerWidth >= 1024)
}

watch(isDesktop, (value, oldValue) => {
  css.value = value
    ? {
        paddingRight: `${props.data.padding.right}px`,
        paddingLeft: `${props.data.padding.right}px`
      }
    : {}
})

onBeforeMount(() => {
  onResizeWindow()
  window.addEventListener('resize', onResizeWindow)
})

onUnmounted(() => {
  window.removeEventListener('resize', onResizeWindow)
})

</script>

<style lang="scss" scoped>
.flexible-content {
  @apply w-full flex flex-col;

  .item:not(:first-of-type){
    @apply mt-md;

    &.item-type-text + .item-type-image,  &.item-type-list + .item-type-cta{
      @apply mt-0;
    }
  }

  .item-type-cta {
    @apply container inner-container mb-lg;
  }

  .item-type-image {
    @apply container inner-container hidden lg:block;
    img {
      @apply h-auto object-cover md:object-scale-down w-full bg-center rounded-base bg-white shadow-sm;
    }
  }

  .item-type-list {
    @apply container inner-container;
  }

  .item-type-text {
    @apply container inner-container;
    .text:deep(h3) {
      @apply text-curie-blue mb-4;
    }
    .text:deep(p:last-of-type){
      @apply mb-0;
    }
  }

  .item-type-title {
    @apply md:container;
  }
}
</style>
