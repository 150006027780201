<template>
  <div class="title-with-cols-button">
    <div
      v-if="data.main_container.title && data.main_container.title.length > 0"
      class="main-title"
      v-html="data.main_container.title"
      :class="paddingCalc(data.main_container.padding_title)"
    />
    <div
      class="inner-section"
      :class="`
        ${paddingCalc(section.settings.padding_section)} ${
        !section.settings.section_settings.full_width ? 'inner-container' : ''
      }
      `"
      v-for="(section, index) of data.main_container.section"
      :key="`inner-section-${index}`"
    >
      <div v-if="section.settings.columns_or_button === 'cols'" class="cols">
        <div
          class="column col-1"
          :class="`
            ${
              section.settings.section_settings.columns === 'one'
                ? 'w-full'
                : section.settings.section_settings.even_columns
                ? 'w-full lg:w-1/2'
                : 'w-full lg:w-7/12'
            } ${
            section.columns.column_1.column_settings.inner_padding
              ? 'gray-section'
              : ''
          } ${
            section.columns.column_1.column_settings.inner_shadow
              ? 'lg:shadow-sm'
              : ''
          } ${
            section.columns.column_1.column_settings.content_type === 'bg_image'
              ? 'bg-image'
              : ''
          }
          `"
          :style="{
            backgroundColor:
              section.columns.column_1.column_settings.background_color,
            backgroundImage:
              section.columns.column_1.column_settings.content_type ===
              'bg_image'
                ? `url(${section.columns.column_1.background_image})`
                : 'none'
          }"
        >
          <div
            v-if="
              section.columns.column_1.column_settings.content_type === 'text'
            "
            v-html="section.columns.column_1.text"
            class="text-content"
          ></div>
          <div
            v-if="
              section.columns.column_1.column_settings.content_type ===
              'gallery'
            "
            class="gallery"
          >
            <h3
              v-if="section.columns.column_1.gallery_title"
              v-html="section.columns.column_1.gallery_title"
            />
            <div class="inner-gallery-container">
              <div
                :class="
                  section.columns.column_1.gallery.length === 1
                    ? 'gallery-item full'
                    : section.columns.column_1.gallery.length === 2
                    ? 'gallery-item half'
                    : section.columns.column_1.gallery.length === 3
                    ? 'gallery-item third'
                    : section.columns.column_1.gallery.length === 4
                    ? 'gallery-item fourth'
                    : 'gallery-item fifth'
                "
                v-for="(item, dx) of section.columns.column_1.gallery"
                :key="`gal-item-${dx}`"
              >
                <img :src="item" alt="" :class="``" />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="section.settings.section_settings.columns === 'two'"
          :class="`
            ${
              section.settings.section_settings.even_columns
                ? 'w-full lg:w-1/2'
                : 'w-full lg:w-[35.6%]'
            } ${
            section.columns.column_2.column_settings.inner_padding
              ? 'gray-section'
              : ''
          } ${
            section.columns.column_2.column_settings.inner_shadow
              ? 'lg:shadow-sm'
              : ''
          } ${
            section.columns.column_2.column_settings.content_type === 'bg_image'
              ? 'bg-image'
              : ''
          }
          `"
          class="column col-2"
          :style="{
            backgroundColor:
              section.columns.column_2.column_settings.background_color,
            backgroundImage:
              section.columns.column_2.column_settings.content_type ===
              'bg_image'
                ? `url(${section.columns.column_2.background_image})`
                : 'none'
          }"
        >
          <div
            v-if="
              section.columns.column_2.column_settings.content_type === 'text'
            "
            v-html="section.columns.column_2.text"
            class="text-content"
          ></div>
          <div
            v-if="
              section.columns.column_2.column_settings.content_type ===
              'gallery'
            "
            class="gallery"
          >
            <h3
              v-if="section.columns.column_2.gallery_title"
              v-html="section.columns.column_2.gallery_title"
            />
            <div class="inner-gallery-container">
              <div
                :class="
                  section.columns.column_2.gallery.length === 1
                    ? 'gallery-item full'
                    : section.columns.column_2.gallery.length === 2
                    ? 'gallery-item half'
                    : section.columns.column_2.gallery.length === 3
                    ? 'gallery-item third'
                    : section.columns.column_2.gallery.length === 4
                    ? 'gallery-item fourth'
                    : 'gallery-item fifth'
                "
                v-for="(item, dx) of section.columns.column_2.gallery"
                :key="`gal-item-${dx}`"
              >
                <img :src="item" alt="" :class="``" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="section.settings.columns_or_button === 'button'"
        class="button"
      >
        <div v-if="section.cta_button" >
          <MainButton
            :text="section.cta_button.title"
            :url="section.cta_button.url"
            :target="section.cta_button.target"
            :iconVisible="true"
            color="orange"
            width="full"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import MainButton from '@/components/utility/Button.vue'
defineProps(['data'])

const paddingCalc = (data) => {
  switch (data) {
    case 'xs':
      return 'mb-xs'
    case 'sm':
      return 'mb-xs lg:mb-sm'
    case 'md':
      return 'mb-sm lg:mb-md'
    case 'lg':
      return 'mb-md lg:mb-lg'
    case 'xl':
      return 'mb-lg lg:mb-xl'
    default:
      return 'mb-xs'
  }
}
</script>
<style lang="scss" scoped>
.title-with-cols-button {
  @apply container;
  .main-title:deep() {
    h3 {
      @apply flex flex-col lg:block;
    }
  }
  .main-title:deep() {
    * {
      @apply text-white relative w-screen -left-[15px] md:w-full md:-left-0;
    }
    *:not(strong) {
      @apply heading  relative w-screen md:w-full -left-[15px] rounded-none md:rounded-base md:-left-0;
    }
    h3 {
      @apply font-normal;
    }
    strong {
      @apply font-bold inline-block ml-4 lg:inline md:ml-0 md:mr-3 w-full;
    }
  }
  .inner-section {
    .cols {
      @apply flex flex-col lg:flex-row justify-between gap-xs;
      .column {
        @apply rounded-base;
        &.gray-section {
          @apply p-[15px] lg:p-[38px] mt-0 rounded-none md:rounded-base relative w-screen -left-[15px] md:w-full md:-left-0;
          &:first-child {
            @apply mt-0;
          }
        }
        &.bg-image {
          @apply min-h-[200px] md:min-h-[350px] lg:min-w-fit bg-cover bg-no-repeat rounded-base bg-center mt-xs md:mt-0 relative w-full -left-0 md:w-full md:-left-0;
        }
        .text-content:deep() {
          h3 {
            @apply mb-sm text-curie-blue;
          }
          p:last-child {
            @apply mb-0 lg:mb-0;
          }
        }
        .gallery {
          @apply min-h-max lg:min-h-[160px] flex justify-center items-center flex-col p-xs lg:p-sm;
          h3 {
            @apply pt-sm lg:pt-0 pb-sm;
          }
          .inner-gallery-container {
            @apply flex justify-center w-full items-center flex-wrap pb-0 gap-9 lg:gap-0;
            .gallery-item {
              @apply h-full flex justify-center items-center;
              img {
                @apply h-full w-auto;
              }
              &.full {
                @apply h-[35px] lg:h-[80px];
              }
              &.half {
                @apply w-1/3 mb-xs min-h-[185px];
              }
              &.third {
                @apply w-1/3 lg:w-4/12 mb-xs;
              }
              &.fourth {
                @apply w-1/3 lg:w-3/12 mb-xs;
              }
              &.fifth {
                @apply w-1/3 lg:w-1/5 mb-sm;
              }
            }
          }
        }
      }
      .col-1 {
      }
      .col-2 {
        @apply mt-md lg:mt-0;
      }
    }
    .button {
    }
  }
}
</style>
