<template>
  <div class="dropdown-wrapper">
    <div class="dropdown" :class="{ active }">
      <div class="dropdown-top" role="button" @click="toggleSlideUpDown()">
        <h3 class="dropdown-top-title">{{ title }}</h3>
        <img
          class="dropdown-top-arrow"
          :class="{ active }"
          src="@/assets/img/arrow-down-black.svg"
        />
      </div>
      <slide-up-down class="dropdown-inner" v-model="active" :duration="300">
        <div class="content">
          <slot></slot>
        </div>
      </slide-up-down>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import SlideUpDown from 'vue3-slide-up-down'
import gsap from 'gsap'

defineProps(['title'])

const active = ref(false)

const toggleSlideUpDown = () => {
  active.value = !active.value
}
</script>

<style lang="scss" scoped>
.dropdown-wrapper {
  @apply pb-[16px];

  .dropdown {
    @apply bg-white rounded-[15px] px-[15px] py-6
    transition-all duration-300;
    &-top {
      @apply select-none flex justify-between items-center min-h-[60px]
      lg:min-h-0;
      &-title {
        @apply max-w-[75%];
      }
      &-arrow {
        @apply transition-all duration-300;

        &.active {
          @apply rotate-180;
        }
      }
    }
    .dropdown-inner {
      .content {
        @apply pt-6 flex flex-col gap-2
        lg:gap-6
        ;
      }
    }
    &.active {
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1),
        0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    }
  }
}
</style>
