<template>
  <div class="team-section" v-if="pageData">
    <div class="back-button" @click="router.go(-1)">
      <img src="@/assets/img/arrow-back.svg" />
      <h3>Back</h3>
    </div>
    <div class="member-info">
      <h1 class="block lg:hidden" v-html="pageData.title.rendered" />
      <h2 class="block mb-md lg:hidden" v-html="pageData.acf.title" />
      <a
        v-if="pageData.acf?.Networking_Link?.url"
        class="block mb-md lg:hidden"
        :href="pageData.acf.Networking_Link.url"
        :target="pageData.acf.Networking_Link.target"
        >{{ pageData.acf.Networking_Link.title }}</a
      >
      <div class="member-picture">
        <img :src="pageData.featured_image.large || defaultImg" alt="" />
      </div>
      <div class="member-bio">
        <h1 class="hidden lg:block" v-html="pageData.title.rendered" />
        <h2 class="hidden lg:block" v-html="pageData.acf.title" />
        <a
          class="linkedin block"
          v-if="pageData.acf?.Networking_Link?.url"
          :href="pageData.acf.Networking_Link.url"
          :target="pageData.acf.Networking_Link.target"
          ></a
        >
        <div class="bio-content" v-html="pageData.acf.content" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import defaultImg from '@/assets/img/default-img.jpg'
const store = useStore()
const router = useRouter()
const props = defineProps(['slug'])
const pageData = ref()

onBeforeMount(async () => {
  await store.dispatch('getSingleBySlug', {
    type: 'team',
    slug: props.slug,
    showLoading: true
  })

  pageData.value = store.getters.singleBySlug({
    type: 'team',
    slug: props.slug,
    showLoading: true
  })
  // console.log(pageData)
})
</script>

<style lang="scss" scoped>
.team-section {
  @apply pt-xl container;
  .back-button {
    @apply flex items-center content-center cursor-pointer w-max;
    h3 {
      @apply text-curie-blue inline-block;
    }
    img {
      @apply inline-block mr-2;
    }
  }
  .member-info {
    @apply mt-lg flex flex-col lg:flex-row inner-container gap-x-20 mb-md lg:mb-xl;
    .member-picture {
      @apply h-[400px] w-full lg:w-4/12 rounded-base overflow-hidden mb-md lg:mb-0;
      img {
        @apply object-cover h-full w-full;
      }
    }
    h2 {
      @apply my-sm;
    }
    a {
      @apply mb-md underline text-curie-blue font-bold;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.03em;
    }
    .member-bio {
      @apply w-full lg:w-7/12;
      h1 {
        @apply text-[60px] leading-[60px] mb-sm;
      }
      h2 {
        @apply mb-sm;
      }
      a.linkedin {
        @apply mb-sm underline content-[''];
        background-image: url('@/assets/img/linkedin.svg');
        width: 30px;
        height: 30px;
      }
      .bio-content:deep() {
        p, a, span {
          @apply large;
        }
        ol,
        ul {
          @apply pl-9 md:pl-14 leading-7 font-normal font-FKGrotesk;
          font-size: 22px;
          letter-spacing: -0.03em;
          margin-bottom: 22px;
        }
        ol {
          @apply list-none;
          counter-reset: item;

          li {
            @apply relative;
            counter-increment: item;

            &:before {
              @apply mr-2 w-5 text-right inline-block absolute -left-7;
              content: counter(item) ". ";
            }
          }
        }
        ul {
          li {
            @apply list-disc;
          }
        }
      }
    }
  }
}
</style>
