<template>
  <div id="website-wrapper" @click="handleClicks">
    <transition name="fade" mode="out-in">
      <SiteIntro v-if="shouldShowIntro" />
    </transition>
    <nav-menu class="main-nav" name="main-menu" />
    <div id="website-content">
      <router-view v-slot="{ Component, route }">
        <transition
          name="fade"
          @leave="beforeEnter"
          @after-leave="completeTransition"
          @before-enter="transitionElement"
        >
          <component :key="route.path" :is="Component" />
        </transition>
      </router-view>
      <app-footer />
    </div>
    <transition name="fade">
      <site-loading v-if="loading" />
    </transition>
  </div>
</template>

<script>
import NavMenu from './components/template-parts/NavMenu/NavMenu.vue'
import AppFooter from './components/template-parts/Footer.vue'
import SiteLoading from './components/utility/SiteLoading.vue'
import SiteIntro from './components/utility/Intro.vue'
import { ScrollSmoother } from './assets/js/ScrollSmoother.js'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import emitter from './composables/useEmitter.js'

gsap.registerPlugin(ScrollTrigger, ScrollSmoother)
export default {
  components: {
    NavMenu,
    AppFooter,
    SiteLoading,
    SiteIntro
  },
  data () {
    return {
      introShown: false,
      screenWidth: screen.width,
      site: this.$store.state.site,
      showMenu: true,
      isMobile: false,
      isTablet: false,
      isDesktop: false,
      locoScroll: null,
      scroll: 0,
      observer: null,
      loader: false
    }
  },
  computed: {
    shouldShowIntro () {
      return !this.introShown && !sessionStorage.getItem('introShown')
    },
    loading () {
      return this.$store.state.site.loading
    },
    logo () {
      if (this.site.logo) {
        return this.$store.getters.singleById({
          type: 'media',
          id: this.site.logo
        })
      } else {
        return ''
      }
    }
  },
  watch: {
    $route (to, from) {
      // this.locoScroll.scrollTop()
      this.locoScroll.effects('[data-speed], [data-lag]', {})
      ScrollTrigger.refresh()
    }
  },
  methods: {
    transitionElement () {
      this.showMenu = true
    },
    completeTransition () {
      this.showMenu = false
    },
    deviceAsign () {
      if (this.screenWidth < 768) {
        this.isMobile = true
        this.isTablet = false
        this.isDesktop = false
      } else if (this.screenWidth >= 768 && this.screenWidth < 1024) {
        this.isTablet = true
        this.isMobile = false
        this.isDesktop = false
      } else if (this.screenWidth >= 1024) {
        this.isTablet = false
        this.isMobile = false
        this.isDesktop = true
      }
    },
    beforeEnter (el, done) {
      emitter.emit('scrollBeforeEnter', done)
    },
    decodeTitle (title) {
      const txt = document.createElement('textarea')
      txt.innerHTML = title
      return txt.value
    },
    convertToRelative (url) {
      const path = url.replace(this.$store.state.site.url, '')
      return path
    },
    isExternal (url) {
      const domain = (url) => {
        return url.replace('http://', '').replace('https://', '').split('/')[0]
      }
      return domain(location.href) !== domain(url)
    },
    hasIntroShown () {
      if (sessionStorage.getItem('introShown')) {
        this.$store.state.site.introShown = true
        this.introShown = true
        return true
      } else {
        return false
      }
    },
    refreshScroll () {
      setTimeout(() => {
        this.locoScroll.effects('[data-speed], [data-lag]', {})
        ScrollTrigger.refresh()
        // console.log('updated height')
      }, 300)
    },
    introComplete () {
      this.introShown = true
      sessionStorage.setItem('introShown', true)
      this.$store.state.site.introShown = true
    },
    getLinkEl (el) {
      while (el.parentNode) {
        if (el.tagName === 'A') return el
        el = el.parentNode
      }
    },
    handleClicks (e) {
      const a = this.getLinkEl(e.target)
      if (a && a.href.includes(this.site.url)) {
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } =
          e
        // don't handle if has class 'no-router'
        if (a.className.includes('no-router')) return
        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return
        // don't handle when preventDefault called
        if (defaultPrevented) return
        // don't handle right clicks
        if (button !== undefined && button !== 0) return
        // don't handle if `target="_blank"`
        if (a.target && a.target.includes('_blank')) return
        // don't handle same page links
        const currentURL = new URL(a.href, window.location.href)
        if (currentURL && currentURL.pathname === window.location.pathname) {
          // if same page link has same hash prevent default reload
          if (currentURL.hash === window.location.hash) {
            e.preventDefault()
          }
        }
        // Prevent default and push to vue-router
        e.preventDefault()
        const path = a.href.replace(this.site.url, '')
        this.$router.push(path)
      }
    },
    scrollInstance () {
      this.locoScroll = ScrollSmoother.create({
        wrapper: '#website-wrapper',
        content: '#website-content',
        smooth: 0,
        effects: false,
        normalizeScroll: false
      })
      this.observer = new ResizeObserver(() => this.refreshScroll()).observe(
        document.querySelector('body')
      )
    }
  },
  created () {
    this.$store.dispatch('getAcfOptions')
  },
  mounted () {
    this.scrollInstance()
    this.deviceAsign()
    this.hasIntroShown()
  },
  destroyed () {
    window.removeEventListener('resize', (e) => {
      this.deviceAsign()
      this.screenWidth = screen.width
    })
    this.observer.disconnect()
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/index.scss';
/* Vue transition classes
-------------------------------------------- */
// .pin-spacer{
//     @apply pointer-events-none overflow-hidden;
// }
// .pin-spacer + .pin-spacer {
//     @apply pointer-events-auto;
// }
.fade-enter-from {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.4s ease-out;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: opacity 0.4s ease-in;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
