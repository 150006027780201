<template>
  <div class="wrapper" ref="wrapper">
    <main class="main" id="global-wrapper">
      <template v-if="page">
        <template v-for="(module, index) in page.acf.modules">
          <template v-if="module.acf_fc_layout === 'container'">
            <ModulesContainer :key="index" :data="module" :index="index">
              <template v-for="(module, index) in module.inner_content">
                <template v-if="module.acf_fc_layout === 'callToAction'">
                  <CallToAction :key="index" :data="module" :index="index" />
                </template>
                <template
                  v-else-if="module.acf_fc_layout === 'flexibleContent'"
                >
                  <FlexibleContentModule
                    :key="index"
                    :data="module"
                    :index="index"
                  />
                </template>
                <template v-else-if="module.acf_fc_layout === 'forms'">
                  <Forms :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'imageModule'">
                  <ImageComponent :key="index" :data="module" :index="index" />
                </template>
                <template
                  v-else-if="module.acf_fc_layout === 'openingPositions'"
                >
                  <OpeningPositions
                    :key="index"
                    :data="module"
                    :index="index"
                  />
                </template>
                <template v-else-if="module.acf_fc_layout === 'phaseSection'">
                  <PhaseSection :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'TeamSection'">
                  <TeamSection :key="index" :data="module" :index="index" />
                </template>
                <template
                  v-else-if="
                    module.acf_fc_layout === 'titleWithColumnsAndButton'
                  "
                >
                  <TitleWithColumnsAndButton
                    :key="index"
                    :data="module"
                    :index="index"
                  />
                </template>
                <template v-else-if="module.acf_fc_layout === 'toggleLogoGrid'">
                  <ToggleLogoGrid :key="index" :data="module" :index="index" />
                </template>
                <template
                  v-else-if="module.acf_fc_layout === 'servicesScroller'"
                >
                  <ServicesScroller
                    :key="index"
                    :data="module"
                    :index="index"
                  />
                </template>
                <template v-else-if="module.acf_fc_layout === 'contactSection'">
                  <Contact :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'thanksSection'">
                  <ThanksSection :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'htmlEditor'">
                  <HTMLEditor
                    :key="index"
                    :data="module"
                    :slug="slug"
                    :index="index"
                  />
                </template>
                <template v-else-if="module.acf_fc_layout === 'featuredTeams'">
                  <FeaturedTeams :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'video'">
                  <VideoSection :key="index" :data="module" :index="index" />
                </template>
              </template>
            </ModulesContainer>
          </template>
          <template v-if="module.acf_fc_layout === 'callToAction'">
            <CallToAction :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'flexibleContent'">
            <FlexibleContentModule :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'forms'">
            <Forms :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'imageModule'">
            <ImageComponent :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'openingPositions'">
            <OpeningPositions :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'phaseSection'">
            <PhaseSection :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'TeamSection'">
            <TeamSection :key="index" :data="module" :index="index" />
          </template>
          <template
            v-else-if="module.acf_fc_layout === 'titleWithColumnsAndButton'"
          >
            <TitleWithColumnsAndButton
              :key="index"
              :data="module"
              :index="index"
            />
          </template>
          <template v-else-if="module.acf_fc_layout === 'toggleLogoGrid'">
            <ToggleLogoGrid :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'servicesScroller'">
            <ServicesScroller :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'contactSection'">
            <Contact :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'htmlEditor'">
            <HTMLEditor
              :key="index"
              :data="module"
              :slug="slug"
              :index="index"
            />
          </template>
          <template v-else-if="module.acf_fc_layout === 'thanksSection'">
            <ThanksSection :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'featuredTeams'">
            <FeaturedTeams :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'video'">
            <VideoSection :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'TwoBoxesAndText'">
            <TwoBoxesAndText :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'BoxListWithCta'">
            <BoxListWithCta :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'TextWithCta'">
            <TextWithCta :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'BoxListAndBio'">
            <BoxListAndBio :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'dropdown_container'">
            <DropdownContainer :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'TitleWithCta'">
            <TitleWithCta :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'InfoBlockComponent'">
            <InfoBlockComponent :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'LargeVideoSection'">
            <LargeVideoSection :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'heroSection'">
            <HeroSection :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'BannerModule'">
            <BannerModule :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'SpacerModule'">
            <SpacerComponent :key="index" :data="module" :index="index" />
          </template>
        </template>
      </template>
    </main>
  </div>
</template>

<script>
import Forms from '@/components/modules/Forms.vue'
import ImageComponent from '@/components/modules/ImageComponent.vue'
import TeamSection from '@/components/modules/TeamSection.vue'
import TitleWithColumnsAndButton from '@/components/modules/TitleWithColumnsAndButton.vue'
import ToggleLogoGrid from '@/components/modules/ToggleLogoGrid.vue'
import ModulesContainer from '@/components/ModulesContainer.vue'
import ServicesScroller from '@/components/modules/ServicesScroller.vue'
import HTMLEditor from '@/components/modules/HTMLEditor.vue'
import FlexibleContentModule from '@/components/modules/FlexibleContent/FlexibleContentModule.vue'
import CallToAction from '@/components/modules/CallToAction.vue'
import OpeningPositions from './modules/OpeningPositions.vue'
import PhaseSection from './modules/phaseSection.vue'
import Contact from '@/components/modules/Contact.vue'
import ThanksSection from '@/components/modules/ThanksSection.vue'
import FeaturedTeams from '@/components/modules/FeaturedTeams.vue'
import VideoSection from '@/components/modules/VideoSection.vue'
import TwoBoxesAndText from '@/components/modules/TwoBoxesAndText.vue'
import BoxListWithCta from '@/components/modules/BoxListWithCta.vue'
import TextWithCta from '@/components/modules/TextWithCta.vue'
import BoxListAndBio from '@/components/modules/BoxListAndBio.vue'
import DropdownContainer from '@/components/modules/DropdownContainer.vue'
import TitleWithCta from '@/components/modules/TitleWithCta.vue'
import InfoBlockComponent from '@/components/modules/InfoBlockComponent.vue'
import LargeVideoSection from '@/components/modules/LargeVideoSection.vue'
import HeroSection from '@/components/modules/HeroSection.vue'
import BannerModule from '@/components/modules/BannerModule.vue'
import SpacerComponent from '@/components/modules/SpacerComponent.vue'

export default {
  components: {
    ModulesContainer,
    Forms,
    ImageComponent,
    TeamSection,
    TitleWithColumnsAndButton,
    ToggleLogoGrid,
    FlexibleContentModule,
    CallToAction,
    OpeningPositions,
    PhaseSection,
    ServicesScroller,
    Contact,
    ThanksSection,
    HTMLEditor,
    FeaturedTeams,
    VideoSection,
    TwoBoxesAndText,
    BoxListWithCta,
    TextWithCta,
    BoxListAndBio,
    DropdownContainer,
    TitleWithCta,
    InfoBlockComponent,
    LargeVideoSection,
    HeroSection,
    SpacerComponent,
    BannerModule
  },
  name: 'MainPage',
  props: {
    slug: {
      type: String,
      required: true
    },
    isHomepage: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      request: {
        type: 'pages',
        slug: this.slug,
        showLoading: true
      },
      introShown: false
    }
  },
  computed: {
    page () {
      this.normalizeData('forms')
      return this.$store.getters.singleBySlug(this.request)
    }
  },
  methods: {
    getPage () {
      this.$store
        .dispatch('getSingleBySlug', this.request)
        .then(() => {
          if (this.page) {
            if (!this.isHomepage) {
              this.$store.dispatch('updateDocTitle', {
                parts: [
                  this.$root.decodeTitle(this.page.title.rendered),
                  this.$store.state.site.name
                ]
              })
            } else {
              this.$store.dispatch('updateDocTitle', {
                parts: [null, this.$store.state.site.name]
              })
            }
          } else {
            this.$router.replace('/404')
          }
        })
        .then(() => {
          // this.$gtag.pageview({
          //   page_path: this.$root.convertToRelative(this.page.link),
          //   page_title: document.title,
          //   page_location: window.location.href,
          // })
        })
    },
    normalizeData (type) {
      if (type) {
        const filtered = this.$store.getters.singleBySlug(this.request)
        const rawData = this.$store.getters.getPosts({ type })
        if (filtered) {
          for (const module of filtered.acf.modules) {
            if (module.acf_fc_layout === type) {
              const data = Object.entries(rawData).map((x) => x[1])
              data.pop()
              module[type] = data
            }
          }
        }
      }
    },
    introComplete () {
      this.introShown = true
      sessionStorage.setItem('introShown', true)
    }
  },
  created () {
    this.getPage()
  },
  mounted () {
    const anchor = this.$router.currentRoute.hash
    this.$nextTick(() => {
      if (anchor && document.querySelector(anchor)) {
        location.href = anchor
      }
    })
  }
}
</script>

<style lang="scss"></style>
