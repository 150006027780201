<template>
  <div class="image-module-component">
    <h1>Heading</h1>
    <h2>Subheadings</h2>
    <h3>H3 title</h3>
    <h4>H4 title</h4>
    <p>Paragraph</p>
    <p class="large">Paragraph large</p>
    <caption>
      Caption
    </caption>
    <div class="toggle-heading">
      <h3 class="text-white">Section Headings Toggles</h3>
    </div>
    <div class="toggle-heading bg-transparent">
      <h3 class="text-curie-blue">Section Headings Toggles</h3>
    </div>
    <img :src="data.module_image_url.url" :alt="data.module_image_url.alt" />
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: ['data'],
  mounted () {
    // console.log(this.data);
  }
}
</script>

<style lang="scss" scoped>
.image-module-component {
  @apply container;
  img {
    @apply min-h-[300px] max-h-[300px] lg:min-h-[600px] lg:max-h-[600px] object-cover w-full;
  }
}
</style>
