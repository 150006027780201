<template>
  <div class="wrapper">
    <main>
      <div class="top-area mb-10 px-6 lg:px-0"></div>
      <article v-if="post" class="single-post">
        <div class="title-area px-6 lg:px-0">
          <div class="title-container">
            <h1 class="title" v-html="post.title.rendered" />
            <p>Published on {{ getDate }}</p>
          </div>
          <div class="spacer mb-15" />
        </div>
        <div v-if="post.featured_image" class="featured-image mt-10">
          <img
            :src="post.featured_image.large"
            :alt="post.featured_image.alt"
            class="w-full max-h-[550px] object-contain object-center"
          />
        </div>
        <div class="content px-6 lg:px-0" v-html="post.content.rendered" />
      </article>
      <div v-if="post" class="categories px-6 lg:px-0 pb-20">
        <p class="text-text mb-4">Category:</p>
        <div class="categories-list flex flex-row gap-6">
          <div
            class="category"
            v-for="(category, index) in post.categories_info"
            :key="index"
          >
            <span
              class="category-name text-[11px] text-white px-4 py-2 uppercase bg-cyan-400"
              >{{ category.name }}</span
            >
          </div>
        </div>
      </div>
      <div
        v-if="relatedPosts.length > 1"
        class="related-posts-section px-6 lg:px-0 grid grid-cols-1 lg:grid-cols-3 gap-12 pb-20"
      ></div>
    </main>
  </div>
</template>

<script>
import { format } from 'date-fns'

export default {
  name: 'Single',
  components: {},
  props: {
    slug: {
      type: String,
      required: false
    },
    newsSlug: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      request: {
        type: 'posts',
        slug: this.slug,
        showLoading: true
      },
      date: '',
      relatedPosts: []
    }
  },
  computed: {
    post () {
      return this.$store.getters.singleBySlug(this.request)
    },
    async readingTime () {
      const words = (await this.post.content.rendered.split(' ').length) + 1
      return `${Math.ceil(words / 200)} min read`
    },
    getDate: {
      async get () {
        const date = await this.post
        return format(new Date(date.date), 'MMMM dd, yyyy')
      },
      default: 'Loading...'
    }
  },
  methods: {
    sortData (data) {
      return {
        info_card_headline: data.title.rendered
          ? data.title.rendered
          : 'Larry H. Miller',
        info_card_content: data.excerpt.rendered
          ? data.excerpt.rendered
          : 'Larry H. Miller',
        card_image: { url: data.featured_image.medium },
        info_card_date: data.date,
        info_card_cta_button: { title: 'Read more', url: data.link }
      }
    },
    getPost () {
      this.$store
        .dispatch('getSingleBySlug', this.request)
        .then(() => {
          if (this.post) {
            this.$store.dispatch('updateDocTitle', {
              parts: [
                this.$root.decodeTitle(this.post.title.rendered),
                this.$store.state.site.name
              ]
            })

            this.date = format(new Date(this.post.date), 'd MMMM yyyy')

            const relatedRequest = {
              type: 'posts',
              params: {
                categories: this.post.categories,
                exclude: this.post.id,
                per_page: 3,
                fields:
                  'id,slug,title,featured_media,link,author,categories,excerpt,better_featured_image,date,modified,content'
              },
              showLoading: false
            }

            this.$store.dispatch('getItems', relatedRequest).then(() => {
              this.relatedPosts =
                this.$store.getters.requestedItems(relatedRequest)
            })
          }
        })
        .then(() => {
          // this.$gtag.pageview({
          //   page_path: this.$root.convertToRelative(this.post.link),
          //   page_title: document.title,
          //   page_location: window.location.href,
          // })
        })
    }
  },
  created () {
    this.getPost()
  },
  updated () {
    // console.log(this.post);
    // console.log(this.relatedPosts);
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>
main:deep() {
  @apply max-w-screen-xl mx-auto my-10;

  .single-post {
    @apply prose max-w-full;

    .content {
      @apply max-w-[840px] mx-auto;
    }

    .figcaption {
      @apply text-center;
    }

    p {
      @apply text-text;
    }

    img {
      @apply my-3;
    }

    a {
      @apply text-black;
    }

    ol,
    ul {
      li {
        @apply text-text text-[16px] font-light leading-6;

        strong {
          @apply text-text;
        }

        b {
          @apply text-text;
        }

        p {
          @apply text-text text-[16px] font-thin;

          b {
            @apply text-text text-[16px] font-normal;
          }
        }
      }
    }

    ul > li::before {
      @apply bg-text;
    }

    .wp-block-gallery {
      @apply mx-0;

      .blocks-gallery-grid {
        @apply flex;

        .blocks-gallery-item {
          @apply w-[33%] p-0 m-0;

          &::before {
            @apply hidden;
          }

          figure {
            @apply m-0 p-2 w-full h-full;

            img {
              @apply object-cover h-full w-full;
            }
          }
        }
      }
    }

    blockquote {
      @apply text-center border-none w-full mx-auto;

      p {
        @apply text-black mt-10 text-center text-[36px] lg:text-[55px] pl-0 not-italic font-light leading-tight lg:leading-10 mb-10;
      }

      strong {
        @apply text-black mt-10 text-center text-[36px] lg:text-[55px] pl-0 not-italic font-light leading-tight lg:leading-10 mb-10;
      }

      ::before,
      ::after {
        @apply hidden;
      }

      cite {
        @apply text-black text-center mb-10 not-italic text-[16px];

        p {
          @apply text-black text-center mb-10 not-italic text-[16px];
        }
      }
    }

    .quote-ref {
      @apply text-black text-center mb-10;
    }

    .wp-block-columns {
      @apply flex flex-col lg:flex-row gap-0 lg:gap-6 mb-4;

      .wp-block-column {
        @apply w-full;
      }
    }

    .wp-block-video {
      @apply w-full lg:w-[80%] my-10 mx-auto;

      video {
        @apply w-full;
      }
    }

    video {
      @apply w-full;
    }

    .title-area {
      @apply grid grid-cols-1 lg:grid-cols-5 border-b border-gray-300;

      .title-container {
        @apply col-span-4;

        h1 {
          @apply font-light text-[32px] mb-4;
        }

        p {
          @apply text-text;
        }
      }

      .social-media {
        @apply col-span-1 flex flex-row justify-end h-max;

        a {
          img {
            filter: brightness(0);
          }
        }
      }
    }

    .content {
      @apply mb-10 mt-10 lg:mb-28 lg:mt-10;
    }
  }
}
</style>
